const headers = {
  "Content-Type": "application/json",
  "x-form-builder-version": "1.0",
};

const endpoints = {
  validatePassportPhotoViaRegula: `${import.meta.env.VITE_PASSPORT_API_URL}/passport/validate`,
  getPortraitUploadUrl: `${import.meta.env.VITE_PASSPORT_API_URL}/passport/get-portrait-upload-url`,
};

const passportPhotoErrorDescriptions: Record<string, string> = {
  ImageChannelsNumber: "Please make sure you use color pictures.",
  FaceMidPointHorizontalPosition:
    "Make sure your face is in the center of the frame.",
  FaceMidPointVerticalPosition:
    "Make sure your face is in the center of the frame.",
  HeadWidthRatio: "Make sure your face fits the frame.",
  HeadHeightRatio: "Make sure your face fits the frame.",
  EyesDistance: "Please move your face closer / further to the camera.",
  Yaw: "Please keep your head straight.",
  Pitch: "Please keep your head straight.",
  Roll: "Please keep your head straight.",
  BlurLevel: "Please make sure the camera is focused on your face.",
  NoiseLevel: "Poor quality of the picture.",
  EyeRightClosed: "Make sure your eyes are open.",
  EyeLeftClosed: "Make sure your eyes are open.",
  EyeRightOccluded: "Make sure your eyes are not occluded.",
  EyeLeftOccluded: "Make sure your eyes are not occluded.",
  EyesRed: "Please retake the picture without flash and in better light.",
  EyeRightCoveredWithHair: "Make sure your eyes are not covered with hair.",
  EyeLeftCoveredWithHair: "Make sure your eyes are not covered with hair.",
  OffGaze: "Please look directly at the camera.",
  FaceDynamicRange:
    "Please retake the picture without a flash and in natural light.",
  UnnaturalSkinTone:
    "Please retake the picture without a flash and in natural light.",
  FaceGlare: "Please retake the picture without a flash and in natural light.",
  ShadowsOnFace:
    "Please retake the picture in a natural light and ensure that there are shadows.",
  TooDark: "Please retake the picture without flash and in a better light.",
  TooLight: "Please retake the picture without a flash and in natural light.",
  DarkGlasses: "Please take the photo without glasses.",
  ReflectionOnGlasses:
    "Please make sure there is no reflection on the glasses.",
  FramesTooHeavy:
    "Please make sure your glasses frames do not cover your face.",
  FaceOccluded: "Make sure your face is not occluded.",
  HeadCovering: "Please make sure you are not wearing a head covering.",
  BackgroundUniformity:
    "Make sure the background is uniform and has no shadows.",
  ShadowsOnBackground:
    "Make sure the background is uniform and has no shadows.",
  OtherFaces: "Make sure there is just one face in the frame.",
  ShouldersPose: "Make sure your shoulders are symmetrical.",
  ExpressionLevel: "Make sure to have a neutral expression.",
  MouthOpen: "Make sure to close your mouth.",
  Smile: "Don't smile.",
  StrongMakeup: "Strong makeup is not allowed on passports.",
  ForeheadCovering: "Make sure your forehead is not covered.",
  Headphones: "Please don't wear headphones.",
  ArtFace: "Please use a photo of yourself without any filter.",
  BackgroundColorMatch:
    "Please ensure the background is a solid color and contrasts with your face.",
};

export { headers, endpoints, passportPhotoErrorDescriptions };
