import React, { memo, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { validatePassportPhotoViaRegula } from "@/api/validatePassportPhotoViaRegula/validatePassportPhotoViaRegula.ts";
import { useBoundStore } from "@/zustand-store/zustand-store.ts";
import { Button } from "@/components/Button/Button.tsx";
import UserSelfie from "@/icons/UserSelfie.tsx";
import { Upload } from "lucide-react";
import GrantPermissionBtn from "@/components/PassportPhoto/ActiveComponent/Dropzone/GrantPermissionBtn/GrantPermissionBtn.tsx";
import TurnOnCameraBtn from "@/components/PassportPhoto/ActiveComponent/Dropzone/TurnOnCameraBtn/TurnOnCameraBtn.tsx";
import { cn } from "@/lib/utils";
import PhotoInstructions from "@/components/PassportPhoto/ActiveComponent/PhotoInstructions/PhotoInstructions.tsx";

function Dropzone() {
  const {
    passportPhoto: {
      config: { maxPhotoSize, acceptedPhotoFormats, containerHeight },
      validation: { setIsValidating },
      webcam: { checkPermission, isPermissionGranted },
    },
  } = useBoundStore();

  useEffect(() => {
    checkPermission();
  }, []);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setIsValidating(true);
    const file = acceptedFiles[0];
    const reader = new FileReader();

    // reader.readAsArrayBuffer(file)
    reader.readAsDataURL(file);

    reader.onabort = () => console.log("file reading was aborted");
    reader.onerror = () => console.log("file reading has failed");

    reader.onloadend = async ({ target }) => {
      if (!target?.result) {
        setIsValidating(false);
        return;
      }

      await validatePassportPhotoViaRegula({
        base64Picture: target.result as string,
        fileType: file.type,
      });
    };
  }, []);

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    accept: acceptedPhotoFormats,
    maxFiles: 1,
    noClick: true,
    maxSize: maxPhotoSize, // 5mb
    onError: (error: Error | null) => console.log({ error }),
  });

  return (
    <div {...getRootProps()} className="fr-flex fr-justify-center fr-w-full">
      <input {...getInputProps()} hidden />
      <div
        className={cn(
          containerHeight,
          "fr-space-y-6 fr-flex fr-flex-col fr-items-center fr-justify-center",
        )}
      >
        <div className="lg:fr-block fr-hidden">
          <UserSelfie />
        </div>
        <div className="lg:fr-hidden">
          <PhotoInstructions />
        </div>
        {isPermissionGranted ? <TurnOnCameraBtn /> : <GrantPermissionBtn />}
        <Button
          variant="link"
          className="fr-flex fr-justify-center fr-space-x-2"
          onClick={open}
        >
          <Upload size={16} color="#000" className="fr-mt-1" />{" "}
          <span className="fr-underline fr-text-black">Browse File</span>
        </Button>
      </div>
    </div>
  );
}

export default memo(Dropzone);
