export default function getBrowserSettingsLink() {
  const userAgent = navigator.userAgent;

  if (userAgent.includes("Edge")) {
    return "https://support.microsoft.com/en-us/windows/windows-camera-microphone-and-privacy-a83257bc-e990-d54a-d212-b5e41beba857#ID0EDDBF";
  }

  if (userAgent.includes("Chrome")) {
    return "https://support.google.com/chrome/answer/114662";
  }

  if (userAgent.includes("Firefox")) {
    return "https://support.mozilla.org/en-US/kb/site-permissions-panel";
  }

  if (
    (userAgent.includes("Safari") && !userAgent.includes("Chrome")) ||
    (userAgent.includes("iPhone") && !userAgent.includes("Chrome"))
  ) {
    return "https://support.apple.com/guide/safari/websites-ibrwe2159f50/mac";
  }

  if (userAgent.includes("android")) {
    return "https://support.google.com/chrome/answer/114662?hl=en&co=GENIE.Platform%3DAndroid";
  }

  return "https://support.google.com/chrome/answer/114662";
}
