import { useBoundStore } from "@/zustand-store/zustand-store.ts";
import { Button } from "@/components/Button/Button.tsx";
import { Grid } from "@/components/Grid/Grid.tsx";
import { GridCol } from "@/components/Grid/Col.tsx";
import { cn } from "@/lib/utils";
import styles from "./progress.module.css";
import { CircleCheckBig, CircleX } from "lucide-react";
import PhotoTipsModal from "@/components/PhotoTipsModal/PhotoTipsModal";

const PhotoNavigation = () => {
  const {
    passportPhoto: {
      config: { maxPhotoAttempts },
      validation: { isValidating, isStaggeringValidationList },
      photos: { attachedPhotos, selectPhoto, selectedPhoto },
    },
  } = useBoundStore();

  const handleSelectPhoto = (index: number) => selectPhoto(index);

  return (
    <>
      <Grid as="ul">
        {Array.from({ length: maxPhotoAttempts }).map((_, index) => {
          const isDisabled =
            attachedPhotos.length <= index ||
            isStaggeringValidationList ||
            isValidating;
          const isSelected = selectedPhoto?.id === index;
          const isLoading =
            (attachedPhotos.length === index && isValidating) ||
            attachedPhotos.find((p) => p.id === index)?.delayedNavBtnState ===
              "loading";
          const isValid =
            attachedPhotos.find((p) => p.id === index)?.delayedNavBtnState ===
            "valid";
          const isInvalid =
            attachedPhotos.find((p) => p.id === index)?.delayedNavBtnState ===
            "invalid";

          return (
            <GridCol span={4} sm={12} key={`photo-nav-btn-${index}`}>
              <Button
                size="sm"
                variant={selectedPhoto?.id === index ? "default" : "outline"}
                disabled={isDisabled}
                onClick={() => (isSelected ? null : handleSelectPhoto(index))}
                className={cn(
                  {
                    [styles.photoValidationProgressBg]: isLoading,
                    "[&&]:hover:fr-bg-red-100": !isSelected && !isValid,
                    "[&&]:hover:fr-bg-green-100": !isSelected && isValid,
                    "[&&]:fr-bg-red-100 [&&]:hover:fr-bg-red-200":
                      isSelected && !isValid,
                    "[&&]:fr-bg-green-300 [&&]:hover:fr-bg-green-400":
                      isSelected && isValid,
                  },
                  "fr-w-full disabled:fr-bg-gray-100 fr-relative",
                )}
              >
                {!isDisabled && isInvalid && (
                  <CircleX
                    size={17}
                    color="red"
                    className={cn("fr-absolute fr-right-[26px]")}
                  />
                )}
                {!isDisabled && isValid && (
                  <CircleCheckBig
                    size={17}
                    color="green"
                    className={cn("fr-absolute fr-right-[26px]")}
                  />
                )}
                <p>
                  Photo {index + 1} {isLoading && "- Validating..."}{" "}
                  {attachedPhotos.length > 0 && isInvalid && "- Failed"}{" "}
                  {attachedPhotos.length > 0 && isValid && "- Success"}
                </p>
              </Button>
            </GridCol>
          );
        })}
      </Grid>
      <PhotoTipsModal />
    </>
  );
};

export default PhotoNavigation;
