import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/Form/Form.tsx";
import { Input } from "@/components/Input/Input.tsx";
import { useApplicantMutations } from "@/hooks";
import { GridCol } from "@/components/Grid/Col.tsx";

import type { FormInputNode } from "@/types/node.ts";
import { useAnalytics, useApplicantContext } from "@/providers";
import { useFormContext } from "react-hook-form";
import { useFieldRules } from "@/hooks/useFieldRules/useFieldRules.ts";
import { useCallback } from "react";

type HandleChangeProps = {
  value: string | boolean;
  onChange: (value: string | boolean) => void;
};

type HandleBlurProps = {
  value: string | boolean;
  onBlur: () => void;
};

const TimeField = ({
  name,
  label,
  description,
  placeholder,
  visibleIf,

  visibleIfOp,
  gridCol,
  rules,
  emptyMessage,
  tooltip,
}: FormInputNode & { gridCol?: number }) => {
  const { control } = useFormContext();
  const { validationRules, isVisible, defaultValue } = useFieldRules({
    fieldName: name,
    rules,
    visibleIf,
    visibleIfOp,
    emptyMessage,
  });
  const { applicantToken } = useApplicantContext();
  const { updateTemporaryApplicantMutation } = useApplicantMutations();
  const { trackFormField } = useAnalytics();

  const handleFocus = useCallback(() => {
    trackFormField({
      fieldType: "time",
      fieldName: name,
      fieldInteraction: "focus",
    });
  }, [trackFormField]);

  const handleChange = useCallback(
    ({ value, onChange }: HandleChangeProps) => {
      onChange(value);

      trackFormField({
        fieldType: "time",
        fieldName: name,
        fieldInteraction: "change",
      });
    },
    [trackFormField],
  );

  const handleBlur = useCallback(
    ({ value, onBlur }: HandleBlurProps) => {
      onBlur();

      if (applicantToken) {
        updateTemporaryApplicantMutation?.mutate({
          field: name,
          value,
        });
      }

      trackFormField({
        fieldType: "time",
        fieldName: name,
        fieldInteraction: "focus-out",
      });
    },
    [applicantToken, updateTemporaryApplicantMutation, trackFormField],
  );

  if (!isVisible) return null;

  return (
    <GridCol span={gridCol} sm={12}>
      <FormField
        name={name}
        control={control}
        rules={validationRules}
        defaultValue={defaultValue}
        render={({ field }) => (
          <FormItem>
            <FormLabel description={description} tooltip={tooltip}>
              {label}
            </FormLabel>
            <FormControl>
              <Input
                type="time"
                placeholder={placeholder}
                {...field}
                onFocus={handleFocus}
                onChange={(e) =>
                  handleChange({
                    value: e.target.value,
                    onChange: field.onChange,
                  })
                }
                onBlur={(e) =>
                  handleBlur({ value: e.target.value, onBlur: field.onBlur })
                }
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </GridCol>
  );
};

export default TimeField;
