import { Stepper } from "@/components/Stepper/Stepper.tsx";
import Page from "@/components/Form/Page/Page.tsx";
import { Button } from "@/components/Button/Button.tsx";
import { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { submitForm } from "@/api";
import { useApplicantMutations } from "@/hooks";
import {
  useAnalytics,
  useApplicantContext,
  useFormMetadataContext,
  useSearchParamsContext,
} from "@/providers";
import { cn, getFieldNames } from "@/lib/utils";
import { useFormContext } from "react-hook-form";
import { parse } from "tldts";

const View = () => {
  const {
    uiSchema,
    product,
    formId,
    currentStep: defaultCurrentStep,
  } = useFormMetadataContext();
  const {
    trigger,
    handleSubmit,
    reset,
    formState: { errors, touchedFields },
    getValues,
    clearErrors,
    setError,
  } = useFormContext();
  const [previousStep, setPreviousStep] = useState(0);
  const [currentStep, setCurrentStep] = useState(defaultCurrentStep);
  const { trackFormStep, register } = useAnalytics();

  if (!uiSchema || !product) {
    console.error("Either product or schema were not loaded");
    return null;
  }
  const { applicantToken } = useApplicantContext();
  const { coupon } = useSearchParamsContext();
  const {
    updateTemporaryApplicantMutation,
    checkPassportExistenceInEtaapiMutation,
  } = useApplicantMutations();

  const errorsExist = Object.keys(errors).length > 0;

  const submitFormMutation = useMutation({
    mutationFn: () => submitForm({ product, applicantToken, getValues }),
    onSuccess: (data) => {
      console.log({ data });
      const applicantId = "123";
      const applicantsAmount = 1;
      const { domain } = parse(window.location.href);
      const thankYouUrl = "canada/thank-you";
      // reset();
      const url =
        import.meta.env.VIDE_ENV === "prd"
          ? new URL(
              `https://payments.govassist.com/287/${applicantId}/${applicantsAmount}`,
            )
          : new URL(
              `https://staging.payments.govassist.com/139/${applicantId}/${applicantsAmount}`,
            );
      const params = new URLSearchParams(url.search);
      params.set("branding_domain", domain as string);
      params.set("source_site_id", window.location.origin);
      thankYouUrl &&
        params.set("ty_url", `${window.location.origin}/${thankYouUrl}`);
      coupon && params.set("coupon", coupon);
      console.log(url.toString(), url);
      window.location.href = url.toString();
    },
  });

  const steps = uiSchema.pages;
  const isLastStep = currentStep === steps.length - 1;

  const processForm = (data: any) => {
    submitFormMutation?.mutate(data);
  };

  const nextStep = async () => {
    const currentStepFields = getFieldNames({ steps, currentStep });
    const doesStepIncludePassportField =
      currentStepFields.includes("passportNumber");

    if (doesStepIncludePassportField) {
      const data = await checkPassportExistenceInEtaapiMutation.mutateAsync({
        product,
        passportNumber: getValues("passportNumber"),
      });
      if (data.exist === true) {
        setError("passportNumber", {
          type: "custom",
          message:
            "We already have a valid application with this passport number. Please contact your support agent if you want to update your information or have any questions.",
        });
        return;
      }
    }

    // Prevent email validation request triggering on `continue` click
    // Validations not related to the request will still trigger
    const skipEmailTrigger = product === "eta" ? "userEmail" : "email";

    const isEmailTouched =
      Object.keys(touchedFields).includes(skipEmailTrigger);

    const output = await trigger(
      currentStepFields.filter((s) =>
        isEmailTouched ? s !== skipEmailTrigger : true,
      ) as any,
    );

    if (!output || errorsExist) return;

    if (isLastStep) {
      await handleSubmit(processForm)();
      return;
    }

    if (currentStep < steps.length - 1) {
      setPreviousStep(currentStep);
      setCurrentStep((step) => step + 1);
      updateTemporaryApplicantMutation.mutate({
        field: "currentPage",
        value: currentStep + 1,
      });
      trackFormStep({ stepIdx: currentStep + 1, direction: "forward" });
    }

    /**
     * On either previous or continue btn click, scroll to the top of the form
     */
    const appRoot = document.getElementById("root");
    appRoot?.scrollIntoView({ behavior: "smooth" });
  };

  const prevStep = () => {
    if (currentStep > 0) {
      setPreviousStep(currentStep);
      setCurrentStep((step) => step - 1);
      clearErrors();
      updateTemporaryApplicantMutation.mutate({
        field: "currentPage",
        value: currentStep - 1,
      });
      trackFormStep({
        stepIdx: currentStep - 1,
        direction: "backward",
      });
    }
  };

  const handleFormBlur = async (e: any) => {
    const { name, value, role } = e.target as HTMLInputElement;
    /**
     * shadcn Radiogroup doesn't seem to attach `name` to either button or input
     */
    const dataAttrName = e.target.getAttribute("data-name");
    /**
     * Hovering any dropdown option (option | listbox) and blurring to other option fires onBlur()
     * If we disable the blur for options then the shadcn select is not firing onBlur() event
     * Select "blurring" is handled separately at `src/components/Form/Fields/SelectField/SelectField.tsx`
     */
    const shadcnSelectElements = ["option", "listbox"];

    /**
     * Clicking on radio fires onBlur() twice due to `button/label click (radio)` and `group (radiogroup)`
     */
    const shadcnRadioGroupElement = "radiogroup";

    const elementsWithCustomBlurHandling = [
      "userEmail",
      "email",
      "switch",
      "phoneNumber",
      "country",
      "checkbox",
    ];

    const disabledBlur = [
      ...shadcnSelectElements,
      shadcnRadioGroupElement,
      ...elementsWithCustomBlurHandling,
    ].includes(role || name || dataAttrName);

    if (value?.length === 0) return;

    if (applicantToken && !disabledBlur) {
      updateTemporaryApplicantMutation?.mutate({
        field: dataAttrName || name,
        value,
      });
    }
  };

  useEffect(() => {
    const titleNode = document.head.querySelector("title");
    if (titleNode) {
      titleNode.textContent = `${uiSchema.pages[currentStep].title} - ${uiSchema.title}`;
    }

    // Register Step Index on Current Step change
    register({ step_idx: currentStep });
  }, [currentStep, register]);

  useEffect(
    function trackFormStepOnLoad() {
      if (defaultCurrentStep === currentStep && previousStep === 0) {
        trackFormStep({
          formId,
          stepIdx: currentStep,
        });
      }
    },
    [defaultCurrentStep, currentStep, previousStep, trackFormStep],
  );

  return (
    <section>
      <Stepper currentStep={currentStep} />
      <form
        onBlur={handleFormBlur}
        onSubmit={handleSubmit(processForm)}
        className="fr-my-4 fr-space-4 fr-space-y-4 fr-flex fr-flex-col"
        method="post"
      >
        <Page currentStep={currentStep} />
        <div
          className={cn(
            "fr-flex fr-space-x-4 fr-flex-end",
            currentStep !== 0 ? "fr-justify-between" : "fr-justify-end",
          )}
        >
          {currentStep !== 0 && (
            <Button
              onClick={prevStep}
              className="fr-w-fit fr-mt-4 fr-bg-off_white-normal hover:fr-bg-off_white-normal-dark fr-text-black"
              type="button"
            >
              Previous Step
            </Button>
          )}
          <Button
            onClick={nextStep}
            className="fr-w-fit fr-mt-4 fr-text-off_white-normal-dark"
            type="button"
            disabled={checkPassportExistenceInEtaapiMutation.isPending}
          >
            {isLastStep ? "Submit" : "Continue"}
          </Button>
        </div>
      </form>
    </section>
  );
};

export default View;
