import {
  Modal,
  ModalBody,
  ModalClose,
  ModalContent,
  ModalDescription,
  ModalHeader,
  ModalTitle,
} from "@/components/Modal/Modal.tsx";
import { Button } from "@/components/Button/Button.tsx";
import { useBoundStore } from "@/zustand-store/zustand-store.ts";
import { usePassportPhotoMutations } from "@/hooks/usePassportPhotoMutationts/usePassportPhotoMutations.ts";
import { useApplicantContext, useFormMetadataContext } from "@/providers";

const SendEmailLinkConfirmModal = () => {
  const { product } = useFormMetadataContext();
  const { applicantToken } = useApplicantContext();
  const { sendEmailToTakePhotoOnMobileMutation } = usePassportPhotoMutations();
  const {
    passportPhoto: {
      email: { isSendConfirmationModalOpen, setIsSendConfirmationModalOpen },
    },
  } = useBoundStore();

  const handleCancelClick = () => setIsSendConfirmationModalOpen(false);

  const handleCloseModal = async () => {
    sendEmailToTakePhotoOnMobileMutation.mutate({
      product: product!,
      token: applicantToken!,
    });
  };

  return (
    <Modal open={isSendConfirmationModalOpen} onOpenChange={handleCancelClick}>
      <ModalContent>
        <ModalHeader>
          <ModalTitle>Send email with link to take photo</ModalTitle>
          <ModalDescription> </ModalDescription>
        </ModalHeader>
        <ModalBody className="md:fr-p-0 fr-px-4 fr-pb-4">
          <p>
            Are you sure you want to send an email with link to take a photo?
          </p>
        </ModalBody>
        <div className="fr-flex fr-space-x-3 fr-justify-end">
          <Button variant="outline" onClick={handleCancelClick}>
            Cancel
          </Button>
          <Button className="fr-text-white" onClick={handleCloseModal}>
            Confirm
          </Button>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default SendEmailLinkConfirmModal;
