import View from "@/View.tsx";
import { useAnalytics, useFormMetadataContext } from "@/providers";
import { useEffect } from "react";

const FormShell = () => {
  const { isLoading, isError, formId } = useFormMetadataContext();
  const { trackForm, register } = useAnalytics();

  useEffect(
    function trackFormOnStatusChange() {
      if (isError) {
        trackForm({ formId, status: "error" });
      } else if (isLoading) {
        trackForm({ formId, status: "loading" });
      } else {
        trackForm({ formId, status: "loaded" });
      }
    },
    [isLoading, isError, formId, trackForm],
  );

  useEffect(
    function registerFormIdOnLoad() {
      register({ form_id: formId });
    },
    [register],
  );

  if (isError) return <p>Couldn't load form</p>;

  if (isLoading) return <p>loading</p>;

  return <View />;
};

export default FormShell;
