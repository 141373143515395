const UserSelfie = () => {
  return (
    <svg
      width="97"
      height="123"
      viewBox="0 0 97 123"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M96.2433 100.772V122.599H0.756744V100.772C3.73595 99.4136 7.39409 98.2382 11.6805 96.9006C23.3744 93.2424 31.4101 92.9992 35.7877 90.324H61.2123C65.6001 92.9992 73.6358 93.2424 85.3196 96.9006C89.606 98.2382 93.2641 99.4136 96.2433 100.772Z"
        fill="#989798"
      />
      <path
        d="M61.5279 76.442H35.4649L33.8841 91.2569C33.8841 100.782 63.0985 100.782 63.0985 91.2569L61.5279 76.442Z"
        fill="#E5E5E5"
      />
      <g opacity="0.2">
        <path
          d="M61.5292 76.442H35.4662L35.071 80.171C35.071 80.171 37.8779 83.7076 44.495 85.2783C48.8625 86.3119 56.0876 84.4878 62.7756 88.2676L61.5292 76.442Z"
          fill="#010101"
        />
      </g>
      <path
        d="M22.3202 14.3747C26.5864 10.4328 27.0221 10.2504 29.1704 8.66958C43.3469 -7.38165 74.2334 0.00560314 78.9555 21.3768C80.0499 26.3219 79.7459 25.8253 76.3918 44.5112C76.108 46.1123 75.1859 53.3577 70.3726 53.5502C68.6296 53.6212 66.8867 53.2867 65.1438 53.3982C58.6888 53.8137 53.6424 60.1369 47.1672 60.1876C40.54 60.2281 35.6456 53.763 29.2008 52.2126C26.0898 51.4627 22.7357 51.6553 20.5266 48.9193C19.2397 47.3283 20.3848 44.8862 19.949 42.9507C16.5543 27.7203 19.3917 17.0701 22.3202 14.3747Z"
        fill="#818181"
      />
      <path
        d="M48.5063 84.1127C71.9143 84.1127 73.333 57.3404 73.333 57.3404C73.333 57.3404 73.404 60.1372 75.6536 60.1372C77.9032 60.1372 78.501 50.3585 79.5853 48.575C80.6797 46.8017 81.7437 39.6679 77.9741 39.6679C74.2045 39.6679 73.333 46.9639 73.333 46.9639L72.3805 47.4097C72.3805 47.4097 72.5325 23.1201 69.7559 19.8267C65.7127 15.0235 63.5645 14.9931 48.4962 14.9931C33.4279 14.9931 31.2796 15.0235 27.2364 19.8267C24.4599 23.1201 24.6119 47.4097 24.6119 47.4097L23.6593 46.9639C23.6593 46.9639 22.7777 39.6679 19.0183 39.6679C15.2487 39.6679 16.3127 46.8017 17.4071 48.575C18.5015 50.3484 19.0892 60.1372 21.3388 60.1372C23.5884 60.1372 23.6593 57.3404 23.6593 57.3404C23.6593 57.3404 25.0983 84.1127 48.5063 84.1127Z"
        fill="#E5E5E5"
      />
      <path
        d="M29.6583 27.4269C27.5607 33.5678 24.7335 37.9251 24.6423 44.5016C23.0615 39.06 20.9132 33.0814 21.4705 27.4371C21.8252 23.8093 22.0177 15.4291 26.0508 14.1421C27.8241 13.5747 31.0769 15.2669 31.5228 16.949C32.1409 19.3709 30.4284 25.1773 29.6583 27.4269Z"
        fill="#818181"
      />
      <path
        d="M56.3206 11.8516C55.5302 3.08621 69.038 21.1945 65.998 22.5321C63.6369 23.5657 56.9692 20.5865 54.527 19.8062C51.6289 18.8942 49.2273 17.962 46.2481 18.3876C43.2689 18.8132 40.5937 18.418 37.5942 18.3673C34.5947 18.3166 31.4838 17.4857 29.1734 15.3172L56.3206 11.8516Z"
        fill="#818181"
      />
      <path
        d="M59.913 15.489C63.4597 16.9482 66.4592 19.7146 68.2021 23.1295C71.6778 29.9392 70.0565 38.3904 73.056 45.4128L76.3797 32.2191L71.3029 16.1173L59.913 15.489Z"
        fill="#818181"
      />
    </svg>
  );
};
export default UserSelfie;
