import type { CommonState } from "@/zustand-store/zustand-store.ts";
import type { SliceCreator } from "@/types";
import type { BtnState, Photo, RegulaPhotoSnippet } from "@/types/regula.ts";
import { extractValidationErrorsFromRegulaPhoto } from "@/zustand-store/passportPhoto/photoSlice/utils.ts";
import { extractMimeTypeFromBase64 } from "@/utils/extractMimeTypeFromBase64/extractMimeTypeFromBase64.ts";

export type PhotosSlice = {
  attachedPhotos: Photo[];
  selectedPhoto: Photo | null;
  isPhotoTipsModalOpen: boolean;
  photoWithTheLeastValidationErrors: Photo | null;
  attachPhoto: (regulaPhoto: RegulaPhotoSnippet) => void;
  selectPhoto: (photo: Photo | number | null) => void;
  updateSelectedPhotoBtnState: (state: BtnState) => void;
  updateAttachedPhotos: (attachedPhotoToUpdate: Photo) => void;
  togglePhotoTipsModal: (isOpen: boolean) => void;
  setPhotoWithTheLeastValidationErrors: () => void;
};

const defaultState = {
  isPhotoTipsModalOpen: false,
  attachedPhotos: [],
  selectedPhoto: null,
  photoWithTheLeastValidationErrors: null,
};

export const createPhotosSlice: SliceCreator<CommonState, PhotosSlice> = (
  set,
  get,
) => ({
  ...defaultState,

  attachPhoto: (regulaPhoto) => {
    const attachedPhotosLength =
      get().passportPhoto.photos.attachedPhotos.length;
    const photo = {
      id: attachedPhotosLength as Photo["id"],
      validationErrors: extractValidationErrorsFromRegulaPhoto(regulaPhoto),
      delayedNavBtnState: "loading" as BtnState,
      mimeType: extractMimeTypeFromBase64(regulaPhoto.src),
      ...regulaPhoto,
    };

    set(
      ({ passportPhoto: { photos } }) => {
        photos.attachedPhotos = [
          ...get().passportPhoto.photos.attachedPhotos,
          photo,
        ];
      },
      false,
      "[photos.attachPhoto]: Attached regula photo to existing photos",
    );

    get().passportPhoto.photos.selectPhoto(photo);
  },

  selectPhoto: (photo) => {
    set(
      ({ passportPhoto: { photos } }) => {
        if (!photo) {
          photos.selectedPhoto = defaultState.selectedPhoto;
        }

        // photo index
        if (typeof photo === "number") {
          photos.selectedPhoto = get().passportPhoto.photos.attachedPhotos[
            photo
          ] as Photo;
          return;
        }

        photos.selectedPhoto = photo;
      },
      false,
      `[photos.selectPhoto]: ${photo === null ? "Removed selected" : "Selected"} photo`,
    );
  },

  updateSelectedPhotoBtnState: (state) => {
    const selectedPhoto = get().passportPhoto.photos.selectedPhoto;

    set(
      ({ passportPhoto: { photos } }) => {
        const photo = {
          ...selectedPhoto,
          delayedNavBtnState: state,
        } as Photo;

        photos.selectedPhoto = photo;
      },
      false,
      `[photos.updateSelectedPhotoBtnState]: Set Photo ${get().passportPhoto.photos.selectedPhoto?.id} state to ${state}`,
    );

    get().passportPhoto.photos.updateAttachedPhotos(
      get().passportPhoto.photos.selectedPhoto!,
    );
  },

  updateAttachedPhotos: (attachedPhotoToUpdate: Photo) => {
    const updatedAttachedPhotos = get().passportPhoto.photos.attachedPhotos.map(
      (p) => {
        if (p.id === attachedPhotoToUpdate?.id) {
          return attachedPhotoToUpdate;
        }
        return p;
      },
    );

    set(
      ({ passportPhoto: { photos } }) => {
        photos.attachedPhotos = updatedAttachedPhotos;
      },
      false,
      "[photos.updateAttachedPhoto]: Updated attached photos",
    );

    if (
      get().passportPhoto.photos.attachedPhotos.length === 3 &&
      !get().passportPhoto.photos.attachedPhotos.some(
        (p) => p.delayedNavBtnState === "valid",
      )
    ) {
      get().passportPhoto.config.updateMaxPhotoAttempts();
      get().passportPhoto.photos.togglePhotoTipsModal(true);
    }

    get().passportPhoto.photos.setPhotoWithTheLeastValidationErrors();
  },

  togglePhotoTipsModal: (state) => {
    set(
      ({ passportPhoto: { photos } }) => {
        photos.isPhotoTipsModalOpen = state;
      },
      false,
      `[photos.togglePhotoTipsModal]: ${state ? "Opened" : "Closed"} modal`,
    );
  },

  setPhotoWithTheLeastValidationErrors: () => {
    const { attachedPhotos } = get().passportPhoto.photos;
    const photoWithTheLeastValidationErrors = attachedPhotos.reduce(
      (shortest: Photo | null, current) =>
        !shortest ||
        current.validationErrors.length < shortest?.validationErrors?.length
          ? current
          : shortest,
      null,
    );

    set(
      ({ passportPhoto: { photos } }) => {
        photos.photoWithTheLeastValidationErrors =
          photoWithTheLeastValidationErrors;
      },
      false,
      "[photos.setPhotoWithTheLeastValidationErrors]: Set photo with the least validation errors",
    );
  },
});
