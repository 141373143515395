type ConvertBase64ToArrayBufferProps = {
  base64: string;
  type: File["type"];
};

export default function convertBase64ToBlob({
  base64,
  type,
}: ConvertBase64ToArrayBufferProps) {
  const base64Data = base64.replace(/^data:image\/\w+;base64,/, "");

  const binaryString = atob(base64Data);
  const len = binaryString.length;
  const uint8Array = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    uint8Array[i] = binaryString.charCodeAt(i);
  }
  // Convert the Uint8Array to a Blob
  const blob = new Blob([uint8Array], { type });
  return blob;
}
