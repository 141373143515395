import {
  createContext,
  type PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import mixpanel from "mixpanel-browser";
import { getNonEmptyProperties } from "@/lib/utils";

type AnalyticsTrack = (
  eventName: string,
  properties: Record<string, any>,
) => void;

type AnalyticsRegister = (properties: Record<string, any>) => void;

type AnalyticsTrackFormSignature = (props: {
  formId: string;
  status: "loading" | "loaded" | "error";
}) => void;

type AnalyticsTrackFormStepSignature = (props: {
  formId?: string;
  stepIdx: number;
  direction?: "forward" | "backward";
}) => void;

type AnalyticsTrackFormFieldSignature = (props: {
  formId?: string;
  stepIdx?: number;
  fieldType: string;
  fieldName: string;
  fieldInteraction: "focus" | "change" | "focus-out";
}) => void;

type AnalyticsContext = {
  track: AnalyticsTrack;
  register: AnalyticsRegister;
  trackForm: AnalyticsTrackFormSignature;
  trackFormStep: AnalyticsTrackFormStepSignature;
  trackFormField: AnalyticsTrackFormFieldSignature;
} | null;

export const AnalyticsContext = createContext<AnalyticsContext>(null);

export const useAnalytics = () => {
  const context = useContext(AnalyticsContext);

  if (!context) {
    throw new Error("<AnalyticsProvider /> provider not found");
  }

  return context;
};

type AnalyticsProviderProps = {
  token?: string;
  debug?: boolean;
  persistence?: "cookie" | "localStorage";
};

export const AnalyticsProvider = ({
  token,
  debug = false,
  persistence = "localStorage",
  children,
}: PropsWithChildren<AnalyticsProviderProps>) => {
  const [globalProperties, setGlobalProperties] = useState<Record<string, any>>(
    {},
  );

  if (token) {
    mixpanel.init(token, {
      debug,
      persistence,
    });
  } else {
    mixpanel.track = (eventName: string, properties: any) => {
      console.log(eventName, properties);
    };
  }

  const track: AnalyticsTrack = (eventName, properties) => {
    mixpanel.track(eventName, properties);
  };

  const trackOnce = useCallback(
    (() => {
      const executed: Record<string, Record<string, any>> = {};

      return (eventName: string, properties: Record<string, any>) => {
        const alreadyExecuted = executed[eventName];

        if (
          alreadyExecuted &&
          JSON.stringify(alreadyExecuted) === JSON.stringify(properties)
        ) {
          return;
        }

        mixpanel.track(eventName, properties);
        executed[eventName] = properties;
      };
    })(),
    [],
  );

  const register: AnalyticsRegister = useCallback(
    (properties) =>
      setGlobalProperties((props) => ({ ...props, ...properties })),
    [],
  );

  const trackForm: AnalyticsTrackFormSignature = ({ formId, status }) => {
    trackOnce("form_view", {
      ...getNonEmptyProperties({
        form_id: formId,
        form_status: status,
      }),
    });
  };

  const trackFormStep: AnalyticsTrackFormStepSignature = useCallback(
    ({ formId, stepIdx, direction = "" }) => {
      trackOnce("form_step_view", {
        ...getNonEmptyProperties(globalProperties),
        ...getNonEmptyProperties({
          form_id: formId,
          step_idx: stepIdx,
          step_direction: direction,
        }),
      });
    },
    [globalProperties],
  );

  const trackFormField: AnalyticsTrackFormFieldSignature = useCallback(
    ({ formId, stepIdx, fieldType, fieldName, fieldInteraction }) => {
      track("form_field_interaction", {
        ...getNonEmptyProperties(globalProperties),
        ...getNonEmptyProperties({
          form_id: formId,
          step_idx: stepIdx,
          field_type: fieldType,
          field_name: fieldName,
          field_interaction: fieldInteraction,
        }),
      });
    },
    [globalProperties],
  );

  const value = useMemo(
    () => ({
      track,
      register,
      trackForm,
      trackFormStep,
      trackFormField,
    }),
    [track, register, trackForm, trackFormStep, trackFormField],
  );

  return (
    <AnalyticsContext.Provider value={value}>
      {children}
    </AnalyticsContext.Provider>
  );
};
