import { Button } from "@/components/Button/Button.tsx";
import { useBoundStore } from "@/zustand-store/zustand-store.ts";
import SendEmailLinkConfirmModal from "@/components/SendEmailLinkConfirmModal/SendEmailLinkConfirmModal.tsx";

const ReminderMessage = () => {
  const {
    passportPhoto: {
      email: { setIsSendConfirmationModalOpen },
    },
  } = useBoundStore();

  const handleBtnClick = () => setIsSendConfirmationModalOpen(true);

  return (
    <>
      <div className="fr-text-center">
        <p>Don't want to take your photo now?</p>
        <p>You can skip this step, and we'll remind you later.</p>
        <Button
          variant="link"
          className="fr-underline fr-px-0 -fr-mt-1"
          onClick={handleBtnClick}
        >
          Send Email With Link To Take Photo On Your Mobile Phone
        </Button>
      </div>
      <SendEmailLinkConfirmModal />
    </>
  );
};

export default ReminderMessage;
