import Dropzone from "@/components/PassportPhoto/ActiveComponent/Dropzone/Dropzone.tsx";
import { useBoundStore } from "@/zustand-store/zustand-store.ts";
import Webcam from "@/components/PassportPhoto/ActiveComponent/Webcam/Webcam.tsx";
import ValidatingPhoto from "@/components/PassportPhoto/ActiveComponent/ValidatingPhoto/ValidatingPhoto.tsx";
import StaggeringValidationList from "@/components/PassportPhoto/ActiveComponent/StaggeringValidationList/StaggeringValidationList.tsx";
import Photo from "@/components/PassportPhoto/ActiveComponent/Photo/Photo.tsx";
import WaitingForPhotoFromMobile from "@/components/PassportPhoto/ActiveComponent/WaitingForPhotoFromMobile/WaitingForPhotoFromMobile.tsx";
import { useQuery } from "@tanstack/react-query";
import { getTokenData } from "@/api";
import { useApplicantContext, useFormMetadataContext } from "@/providers";
import PreparingForUpload from "@/components/PassportPhoto/ActiveComponent/PreparingForUpload/PreparingForUpload.tsx";
import UploadingPhoto from "@/components/PassportPhoto/ActiveComponent/UploadingPhoto/UploadingPhoto.tsx";
import { memo } from "react";

const ActiveComponent = () => {
  const { product } = useFormMetadataContext();
  const { applicantToken } = useApplicantContext();
  const {
    passportPhoto: {
      webcam: { isActive, isActivating },
      validation: { isValidating, isStaggeringValidationList },
      photos: { selectedPhoto },
      email: { isWaitingForPhotoUploadOnMobile },
      upload: { isPreparingPhotoForUpload, isUploading },
    },
  } = useBoundStore();

  // Polling for Step 1a
  useQuery({
    queryKey: ["pollApplicantForPhotoValidation"],
    queryFn: () => getTokenData({ product: product!, token: applicantToken! }),
    enabled: isWaitingForPhotoUploadOnMobile,
    refetchInterval: 15000, // 15s
  });

  // Step 1: Take a photo through webcam or put file into FileReader
  if (
    !isActive &&
    !isActivating &&
    !isValidating &&
    !isStaggeringValidationList &&
    !selectedPhoto &&
    !isWaitingForPhotoUploadOnMobile &&
    !isPreparingPhotoForUpload &&
    !isUploading
  ) {
    return <Dropzone />;
  }

  // Step 1a: If "send email with link to take photo on phone" has been clicked
  if (isWaitingForPhotoUploadOnMobile) {
    return <WaitingForPhotoFromMobile />;
  }

  // Step 1b: Turn on webcam and take a pic
  if (isActivating || isActive) {
    return <Webcam />;
  }

  // Step 2: Show validation progress and validate photo
  if (isValidating) {
    return <ValidatingPhoto />;
  }

  // Step 3: Stagger validation results
  if (isStaggeringValidationList) {
    return <StaggeringValidationList />;
  }

  // Step 4: Show photo
  if (!isStaggeringValidationList && selectedPhoto) {
    return <Photo />;
  }

  // Step 5: Prepare for upload (get s3 bucket link)
  if (isPreparingPhotoForUpload) {
    return <PreparingForUpload />;
  }

  // Step 6: Upload
  if (isUploading) {
    return <UploadingPhoto />;
  }
};

export default memo(ActiveComponent);
