import type { Quality, RegulaPhotoSnippet } from "@/types/regula.ts";
import { decamelCaseStr } from "@/lib/utils/decamelCaseStr";
import { passportPhotoErrorDescriptions } from "@/constants.ts";
import { uniqueArrayByKey } from "@/utils/uniqueArrayByKey/uniqueArrayByKey.ts";

type ResultType = {
  name: string;
  description: string;
};

const extractValidationErrorsFromRegulaPhoto = (
  regulaPhoto: RegulaPhotoSnippet,
) => {
  const results = regulaPhoto.quality.nonCompliant.map((validationName) => {
    const decamelCasedName = decamelCaseStr(validationName);

    return {
      name: decamelCasedName,
      description: passportPhotoErrorDescriptions[validationName],
    };
  });

  const uniqueResults = uniqueArrayByKey<ResultType>({
    data: results,
    key: "description",
  });

  return uniqueResults;
};

const getValidationResults = (regulaPhotoQuality: Quality) =>
  regulaPhotoQuality.details.map((d) => {
    const decamelCasedName = decamelCaseStr(d.name);

    return {
      originalName: d.name,
      validationName: `Checking ${decamelCasedName}`,
      isValid: Boolean(d.status),
    };
  });

export { extractValidationErrorsFromRegulaPhoto, getValidationResults };
