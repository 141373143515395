import { Button } from "@/components/Button/Button.tsx";
import React from "react";
import { useBoundStore } from "@/zustand-store/zustand-store.ts";
import getBrowserSettingsLink from "@/utils/getBrowserSettingsLink/getBrowserSettingsLink.ts";

const GrantPermissionBtn = () => {
  const {
    passportPhoto: {
      webcam: {
        isPermissionStatusAwaiting,
        isPermissionPrompted,
        isPermissionDenied,
        promptPermission,
      },
    },
  } = useBoundStore();

  const handleGrantPermissionClick = () => promptPermission();

  const showWaitingForActionPrompt =
    isPermissionStatusAwaiting ||
    (!isPermissionStatusAwaiting &&
      !isPermissionPrompted &&
      !isPermissionDenied);

  return (
    <div className="fr-flex fr-flex-col fr-items-center">
      {!isPermissionStatusAwaiting && isPermissionPrompted && (
        <Button
          onClick={handleGrantPermissionClick}
          className="fr-px-[40px] fr-max-w-[250px] fr-text-white"
          disabled={isPermissionStatusAwaiting}
        >
          Grant Camera Permission
        </Button>
      )}

      {!isPermissionStatusAwaiting &&
        (isPermissionDenied || isPermissionPrompted) && (
          <div className="fr-mt-3 fr-text-center">
            <p>
              You can't take a photo until you grant us permission to use your
              camera.
            </p>
            {isPermissionDenied && (
              <a
                className="fr-underline fr-text-blue-700"
                href={getBrowserSettingsLink()}
                rel="noreferrer no-referrer"
                target="_blank"
              >
                Here is how you can set those permissions on your device
              </a>
            )}
          </div>
        )}

      {showWaitingForActionPrompt && (
        <div className="fr-text-center">
          <p>A permission prompt has been shown.</p>
          <p>
            Please follow the instructions in the popup on your device to grant
            camera access.
          </p>
        </div>
      )}
    </div>
  );
};

export default GrantPermissionBtn;
