import { endpoints, headers } from "@/constants.ts";
import { useBoundStore } from "@/zustand-store/zustand-store.ts";
import type { Photo } from "@/types/regula.ts";

type GetPortraitUploadUrlProps = {
  applicationType: string;
  applicationToken: string;
};

export async function getPortraitUploadUrl({
  applicationType,
  applicationToken,
}: GetPortraitUploadUrlProps) {
  const { getState } = useBoundStore;
  getState().passportPhoto.upload.setIsPreparingPhotoForUpload(true);
  const { quality, mimeType } = getState().passportPhoto.photos
    .photoWithTheLeastValidationErrors as Photo;

  try {
    const response = await fetch(endpoints.getPortraitUploadUrl, {
      method: "POST",
      headers,
      body: JSON.stringify({
        applicationType,
        applicationToken,
        contentType: mimeType,
        extraData: quality,
      }),
    });

    const s3Data = await response.json();

    return s3Data.uploadUrl;
  } catch (e) {
    console.log(e);
    return null;
  } finally {
    getState().passportPhoto.upload.setIsPreparingPhotoForUpload(false);
  }
}
