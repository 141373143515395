import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/Select/Select.tsx";
import { Video } from "lucide-react";
import { useBoundStore } from "@/zustand-store/zustand-store.ts";

const SelectDevice = () => {
  const {
    passportPhoto: {
      webcam: { activeDeviceId, devices, setActiveDeviceId },
    },
  } = useBoundStore();

  const handleDeviceChange = (deviceId: MediaDeviceInfo["deviceId"]) =>
    setActiveDeviceId(deviceId);

  const activeDeviceLabel = devices.find(
    (d) => d.value === activeDeviceId,
  )?.label;

  return (
    <Select onValueChange={handleDeviceChange}>
      <SelectTrigger className="w-[180px]">
        <SelectValue
          placeholder={
            <div className="fr-flex fr-max-w-[250px] fr-space-x-3 fr-items-center">
              <Video />{" "}
              <p className="fr-truncate">
                {activeDeviceLabel || "Select device"}
              </p>
            </div>
          }
        />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {devices.map((device) => (
            <SelectItem value={device.value} key={device.value}>
              {device.label}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};

export default SelectDevice;
