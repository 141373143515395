import { cn } from "@/lib/utils";
import { useBoundStore } from "@/zustand-store/zustand-store.ts";

const NoCameraFound = () => {
  const {
    passportPhoto: {
      config: { containerHeight },
    },
  } = useBoundStore();
  return (
    <div
      className={cn(
        containerHeight,
        "fr-flex fr-flex-col fr-space-y-4 fr-justify-center",
      )}
    >
      <p>No camera found</p>
    </div>
  );
};

export default NoCameraFound;
