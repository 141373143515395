import type { Quality } from "@/types/regula.ts";
import type { CommonState } from "@/zustand-store/zustand-store.ts";
import type { SliceCreator } from "@/types";
import { getValidationResults } from "@/zustand-store/passportPhoto/photoSlice/utils.ts";

type ValidationResults =
  | {
      originalName: string;
      validationName: string;
      isValid: boolean;
    }[]
  | [];

export type ValidationSlice = {
  isValidating: boolean;
  // Intentional staggered delay of validations list to show users one by one which validation passed and which failed
  isStaggeringValidationList: boolean;
  results: ValidationResults;
  setValidationResults: (regulaPhotoQuality: Quality) => void;
  setIsValidating: (isValidating: boolean) => void;
  startStaggeringValidationList: (listSize: number) => void;
};

const defaultState = {
  isValidating: false,
  isStaggeringValidationList: false,
  results: [],
};

export const createValidationSlice: SliceCreator<
  CommonState,
  ValidationSlice
> = (set, get) => ({
  ...defaultState,

  setValidationResults: (regulaPhotoQuality) => {
    set(
      ({ passportPhoto: { validation } }) => {
        validation.results = getValidationResults(regulaPhotoQuality);
      },
      false,
      "[validation.setValidationResults]: Validation results set",
    );
  },

  setIsValidating: (isValidating) => {
    set(
      ({ passportPhoto: { validation } }) => {
        validation.isValidating = isValidating;

        if (isValidating) {
          validation.results = [];
        }
      },
      false,
      `[validation.setIsValidating]: ${isValidating ? "Started" : "Finished"} validating`,
    );
  },

  startStaggeringValidationList: (listSize) => {
    const delay = listSize * get().passportPhoto.config.staggerDelayMs;

    set(
      ({ passportPhoto: { validation } }) => {
        validation.isStaggeringValidationList = true;
      },
      false,
      "[validation.startStaggeringValidationList]: Started staggering validation list",
    );

    setTimeout(() => {
      set(
        ({ passportPhoto: { validation } }) => {
          validation.isStaggeringValidationList = false;
        },
        false,
        "[validation.startStaggeringValidationList]: Finished staggering validation list",
      );

      const btnState = get().passportPhoto.validation.results.every(
        (r) => r.isValid,
      )
        ? "valid"
        : "invalid";
      get().passportPhoto.photos.updateSelectedPhotoBtnState(btnState);
    }, delay);
  },
});
