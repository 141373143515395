const getNonEmptyProperties = (properties: Record<string, any>) =>
  Object.keys(properties).reduce(
    (acc, key) => {
      return typeof properties[key] !== "undefined" && properties[key] !== null
        ? { ...acc, [key]: properties[key] }
        : acc;
    },
    {} as Record<string, any>,
  );

export { getNonEmptyProperties };
