import { useBoundStore } from "@/zustand-store/zustand-store.ts";
import { Button } from "@/components/Button/Button.tsx";
import { useDropzone } from "react-dropzone";
import ValidationErrorsList from "@/components/PassportPhoto/ActiveComponent/Photo/ValidationErrorsList/ValidationErrorsList.tsx";
import React from "react";
import ValidationSuccessMsg from "@/components/PassportPhoto/ActiveComponent/Photo/ValidationSuccessMsg/ValidationSuccessMsg.tsx";
import { cn } from "@/lib/utils";

const Photo = () => {
  const {
    passportPhoto: {
      config: { containerHeight },
      photos: { selectedPhoto, selectPhoto },
    },
  } = useBoundStore();

  const handleRetakePhotoClick = () => selectPhoto(null);

  if (!selectedPhoto) return null;

  return (
    <div
      className={cn(
        containerHeight,
        "fr-flex fr-flex-col fr-items-center fr-space-y-3 fr-justify-evenly",
      )}
    >
      <img
        className="fr-max-w-[150px] fr-max-h-[150px] fr-shadow-lg fr-rounded-md"
        src={selectedPhoto.src}
      />
      {selectedPhoto.validationErrors.length > 0 ? (
        <>
          <ValidationErrorsList />
          <div className="fr-flex">
            <Button className="fr-text-white" onClick={handleRetakePhotoClick}>
              Retake Photo
            </Button>
          </div>
        </>
      ) : (
        <ValidationSuccessMsg />
      )}
    </div>
  );
};

export default Photo;
