import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/Form/Form.tsx";
import type { FormInputNode } from "@/types/node.ts";
import { GridCol } from "@/components/Grid/Col.tsx";
import { useFormContext } from "react-hook-form";
import { useFieldRules } from "@/hooks/useFieldRules/useFieldRules.ts";
import { PhoneInput } from "@/components/PhoneInput/PhoneInput.tsx";
import {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  getCountryCallingCode,
  isPossiblePhoneNumber,
} from "react-phone-number-input";
import { useApplicantMutations } from "@/hooks";
import { useAnalytics, useApplicantContext } from "@/providers";
import { type FocusEvent, useCallback, useState } from "react";

type HandleChangeProps = {
  value: any;
  onChange: (value: any) => void;
};

const PhoneInputField = ({
  name,
  label,
  description,
  placeholder,
  visibleIf,
  visibleIfOp,
  gridCol,
  rules,
  tooltip,
  emptyMessage,
}: FormInputNode & { gridCol?: number }) => {
  const { control, getValues, setError, clearErrors } = useFormContext();
  const [countryCode, setCountryCode] = useState(
    getValues("phoneCode")?.split?.(":")?.[1] ?? "",
  );
  const [isPossible, setIsPossible] = useState(
    isPossiblePhoneNumber(getValues("phoneNumber")),
  );
  const { applicantToken } = useApplicantContext();
  const { updateTemporaryApplicantMutation } = useApplicantMutations();
  const { validationRules, isVisible } = useFieldRules({
    fieldName: name,
    rules,
    visibleIf,
    visibleIfOp,
    emptyMessage,
  });
  const { trackFormField } = useAnalytics();

  const handleInputChange = useCallback(
    ({ onChange, value }: HandleChangeProps) => {
      onChange(value);
      const isPhoneNumberPossible = isPossiblePhoneNumber(value, countryCode);
      setIsPossible(isPhoneNumberPossible);

      trackFormField({
        fieldType: "phone-input",
        fieldName: name,
        fieldInteraction: "change",
      });
    },
    [countryCode, setIsPossible, trackFormField],
  );

  const handleInputBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      const callingCode = getCountryCallingCode(countryCode);

      trackFormField({
        fieldType: "phone-input",
        fieldName: name,
        fieldInteraction: "focus-out",
      });

      if (!callingCode) return;
      if (applicantToken && e.target.value && countryCode) {
        if (!isPossible) {
          setError(name, {
            type: "custom",
            message: "Enter a valid phone number",
          });
        }

        if (isPossible) {
          clearErrors(name);
          const phoneNumber = e.target.value.replaceAll(" ", "");
          updateTemporaryApplicantMutation?.mutate({
            fields: {
              phoneNumber,
              phoneCode:
                `${callingCode}:${countryCode}` || getValues("phoneCode"),
            },
          });
        }
      }
    },
    [
      applicantToken,
      updateTemporaryApplicantMutation,
      countryCode,
      isPossible,
      trackFormField,
      getValues,
      setError,
      clearErrors,
    ],
  );

  const handleFocus = useCallback(() => {
    trackFormField({
      fieldType: "phone-input",
      fieldName: name,
      fieldInteraction: "focus",
    });
  }, [trackFormField]);

  const handleCountryChange = (countryCode: any) => {
    if (!countryCode) return;
    setCountryCode(countryCode);
  };

  if (!isVisible) return null;

  return (
    <GridCol span={gridCol} sm={12}>
      <FormField
        control={control}
        name={name}
        rules={validationRules}
        render={({ field }) => (
          <FormItem>
            <FormLabel description={description} tooltip={tooltip}>
              {label}
            </FormLabel>
            <FormControl>
              <PhoneInput
                placeholder={placeholder}
                defaultCountry={countryCode}
                {...field}
                onFocus={handleFocus}
                onBlur={handleInputBlur}
                onChange={(value) =>
                  handleInputChange({ onChange: field.onChange, value })
                }
                onCountryChange={(value) => handleCountryChange(value)}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </GridCol>
  );
};

export default PhoneInputField;
