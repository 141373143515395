import { CircleCheckBig } from "lucide-react";

const ValidationSuccessMsg = () => {
  return (
    <div className="fr-flex fr-flex-col fr-items-center fr-space-y-4">
      <div className="fr-rounded-full fr-p-4 fr-bg-green-200 fr-shadow-md">
        <CircleCheckBig size={26} color="green" />
      </div>
      <p className="fr-text-center fr-px-3">
        Your photo has been successfully verified.
      </p>
    </div>
  );
};

export default ValidationSuccessMsg;
