import { headers } from "@/constants.ts";
import { toast } from "react-toastify";

type SendEmailToTakePhotoOnMobileProps = {
  product: string;
  token: string;
};

export async function sendEmailToTakePhotoOnMobile({
  product,
  token,
}: SendEmailToTakePhotoOnMobileProps) {
  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/${product}/temporary/${token}/send-email`,
      {
        method: "POST",
        headers,
      },
    );

    if (!response.ok) {
      toast.error("Uh oh! Something went wrong.");
    }
    const obj = await response.json();
    console.log({ obj });
  } catch (e) {}
}
