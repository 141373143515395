import { Button } from "@/components/Button/Button.tsx";
import React from "react";
import { useBoundStore } from "@/zustand-store/zustand-store.ts";

const TurnOnCameraBtn = () => {
  const {
    passportPhoto: {
      webcam: { setIsActivating },
    },
  } = useBoundStore();

  const handleTakePhotoClick = () => setIsActivating(true);

  return (
    <Button
      className="fr-px-[40px] fr-max-w-[150px] fr-text-white"
      onClick={handleTakePhotoClick}
    >
      Turn Camera On
    </Button>
  );
};

export default TurnOnCameraBtn;
