import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/Form/Form.tsx";
import { Input } from "@/components/Input/Input.tsx";
import type { FormEmailNode } from "@/types/node.ts";
import { GridCol } from "@/components/Grid/Col.tsx";
import { useFormContext } from "react-hook-form";
import { useCallback, useRef } from "react";
import { useFieldRules } from "@/hooks/useFieldRules/useFieldRules.ts";
import { useAnalytics } from "@/providers";

type HandleChangeProps = {
  onChange: (value: string) => void;
  value: string;
};

type HandleBlurProps = {
  onBlur: (value: string) => void;
  value: string;
};

const EmailField = ({
  name,
  label,
  description,
  placeholder,
  visibleIf,
  visibleIfOp,
  gridCol,
  rules,
  emptyMessage,
  tooltip,
}: FormEmailNode & { gridCol?: number }) => {
  const { control } = useFormContext();
  const ref = useRef<HTMLInputElement>(null);
  const { validationRules, isVisible } = useFieldRules({
    fieldName: name,
    rules,
    visibleIf,
    visibleIfOp,
    emptyMessage,
    ref,
  });
  const { trackFormField } = useAnalytics();

  const handleFocus = useCallback(() => {
    trackFormField({
      fieldType: "email",
      fieldName: name,
      fieldInteraction: "focus",
    });
  }, [trackFormField]);

  const handleChange = useCallback(
    ({ onChange, value }: HandleChangeProps) => {
      onChange(value);

      trackFormField({
        fieldType: "email",
        fieldName: name,
        fieldInteraction: "change",
      });
    },
    [trackFormField],
  );

  const handleBlur = useCallback(
    ({ onBlur, value }: HandleBlurProps) => {
      onBlur(value);

      trackFormField({
        fieldType: "email",
        fieldName: name,
        fieldInteraction: "focus-out",
      });
    },
    [trackFormField],
  );

  if (!isVisible) return null;

  return (
    <GridCol span={gridCol} sm={12}>
      <FormField
        name={name}
        control={control}
        rules={validationRules}
        render={({ field: { onBlur, ...props } }) => (
          <FormItem>
            <FormLabel description={description} tooltip={tooltip}>
              {label}
            </FormLabel>
            <FormControl>
              <Input
                placeholder={placeholder}
                {...props}
                onFocus={handleFocus}
                onChange={(e) =>
                  handleChange({
                    onChange: props.onChange,
                    value: e.target.value,
                  })
                }
                onBlur={(e) => handleBlur({ onBlur, value: e.target.value })}
                ref={ref}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </GridCol>
  );
};

export default EmailField;
