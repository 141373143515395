import type { CommonState } from "@/zustand-store/zustand-store.ts";
import type { SliceCreator } from "@/types";

export type WebcamSlice = {
  isPermissionStatusAwaiting: boolean;
  isPermissionPrompted: boolean;
  isPermissionGranted: boolean;
  isPermissionDenied: boolean;
  isActivating: boolean;
  isActive: boolean;
  facingMode: VideoFacingModeEnum;
  devices: {
    label: MediaDeviceInfo["label"];
    value: MediaDeviceInfo["deviceId"];
  }[];
  activeDeviceId: string | null;
  // Once we click "Grant Camera Permission"
  setIsActive: (isActive: boolean) => void;
  setIsActivating: (isActivating: boolean) => void;
  setDevicesList: () => void;
  setActiveDeviceId: (deviceId: MediaDeviceInfo["deviceId"]) => void;
  toggleFacingMode: () => void;
  checkPermission: () => void;
  promptPermission: () => void;
};

const defaultState = {
  isPermissionStatusAwaiting: false,
  isPermissionPrompted: false,
  isPermissionGranted: false,
  isPermissionDenied: false,
  isActivating: false,
  isActive: false,
  facingMode: "user" as VideoFacingModeEnum,
  activeDeviceId: null,
  devices: [],
};

export const createWebcamSlice: SliceCreator<CommonState, WebcamSlice> = (
  set,
  get,
) => ({
  ...defaultState,

  checkPermission: async () => {
    // https://github.com/microsoft/TypeScript/issues/33923
    const permissionName = "camera" as PermissionName;
    const permission = await navigator.permissions.query({
      name: permissionName,
    });

    set(
      ({ passportPhoto: { webcam } }) => {
        // if status !== granted or status !== denied, status === prompt (even if we don't show the popup)
        webcam.isPermissionPrompted = permission?.state === "prompt" || false;
        webcam.isPermissionGranted = permission?.state === "granted" || false;
        webcam.isPermissionDenied = permission?.state === "denied" || false;
      },
      false,
      `[webcam.checkPermission]: Permission ${permission?.state}`,
    );

    if (get().passportPhoto.webcam.isPermissionGranted) {
      get().passportPhoto.webcam.setDevicesList();
    }
  },

  promptPermission: async () => {
    set(
      ({ passportPhoto: { webcam } }) => {
        webcam.isPermissionStatusAwaiting = true;
        webcam.isPermissionPrompted = false;
      },
      false,
      "[webcam.promptPermission]: Started permission status await",
    );

    try {
      await navigator.mediaDevices.getUserMedia({ video: true });
    } catch (e) {
      console.log(e);
    } finally {
      get().passportPhoto.webcam.checkPermission();
      set(
        ({ passportPhoto: { webcam } }) => {
          webcam.isPermissionStatusAwaiting = false;
        },
        false,
        "[webcam.promptPermission]: Finished permission status await",
      );
    }
  },

  setDevicesList: async () => {
    const allDevices = await navigator.mediaDevices.enumerateDevices();
    const videoDevices = allDevices.filter(({ kind }) => kind === "videoinput");
    const devices = videoDevices.map((d) => ({
      label: d.label,
      value: d.deviceId,
    }));

    set(
      ({ passportPhoto: { webcam } }) => {
        webcam.devices = devices;
      },
      false,
      "[webcam.setDevicesList]: Updated devices list",
    );

    if (videoDevices[0].deviceId) {
      get().passportPhoto.webcam.setActiveDeviceId(videoDevices[0].deviceId);
    }
  },

  setActiveDeviceId: (deviceId) => {
    set(
      ({ passportPhoto: { webcam } }) => {
        webcam.activeDeviceId = deviceId;
      },
      false,
      `[webcam.setActiveDeviceId]: Set active device id - ${deviceId}`,
    );
  },

  setIsActivating: (isActivating) => {
    set(
      ({ passportPhoto: { webcam } }) => {
        webcam.isActivating = isActivating;
      },
      false,
      `[webcam.setIsActivating]: ${isActivating ? "Started" : "Finished"} turning Webcam on`,
    );
  },

  setIsActive: (isActive) => {
    set(
      ({ passportPhoto: { webcam } }) => {
        webcam.isActive = isActive;
      },
      false,
      `[webcam.setIsActive]: Webcam ${isActive ? "on" : "off"}`,
    );
  },

  toggleFacingMode: () => {
    set(
      ({ passportPhoto: { webcam } }) => {
        if (get().passportPhoto.webcam.facingMode === "user") {
          webcam.facingMode = "environment";
        } else {
          webcam.facingMode = "user";
        }
      },
      false,
      "[webcam.toggleFacingMode]: Toggled face mode",
    );
  },
});
