import type { CommonState } from "@/zustand-store/zustand-store.ts";
import type { SliceCreator } from "@/types";

export type UploadSlice = {
  isUploading: boolean;
  isPreparingPhotoForUpload: boolean;
  setIsPreparingPhotoForUpload: (isUploading: boolean) => void;
  setIsUploading: (isUploading: boolean) => void;
};

const defaultState = {
  isPreparingPhotoForUpload: false,
  isUploading: false,
};

export const createUploadSlice: SliceCreator<CommonState, UploadSlice> = (
  set,
) => ({
  ...defaultState,

  setIsPreparingPhotoForUpload: (isPreparingPhotoForUpload) => {
    set(
      ({ passportPhoto: { photos, upload } }) => {
        photos.selectedPhoto = null;
        upload.isPreparingPhotoForUpload = isPreparingPhotoForUpload;
      },
      false,
      `[upload.setIsPreparingPhotoForUpload]: ${isPreparingPhotoForUpload ? "Started" : "Finished"} preparing photo for upload`,
    );
  },

  setIsUploading: (isUploading) => {
    set(
      ({ passportPhoto: { upload } }) => {
        upload.isUploading = isUploading;
      },
      false,
      `[upload.setIsUploading]: ${isUploading ? "Started" : "Finished"} uploading`,
    );
  },
});
