import type { CommonState } from "@/zustand-store/zustand-store.ts";
import type { SliceCreator } from "@/types";

export type ConfigSlice = {
  acceptedPhotoFormats: Record<File["type"], string[]>;
  containerHeight: string;
  animationDuration: number; // in seconds
  staggerDelayMs: number; // in miliseconds
  staggerDelayS: number; // in seconds
  maxPhotoSize: number;
  maxPhotoAttempts: 3 | 6;
  updateMaxPhotoAttempts: () => void;
};

const defaultState = {
  acceptedPhotoFormats: {
    "image/jpeg": [".jpeg", ".jpg"],
    "image/png": [".png"],
    "image/webp": [".webp"],
  },
  // fixed height avoids any container dimension movements between photo steps
  containerHeight: "sm:fr-h-[356px] fr-min-h-[412px] fr-max-h-[500px]",
  animationDuration: 0.1,
  staggerDelayMs: 150,
  staggerDelayS: 0.15,
  maxPhotoSize: 5e6, // 5mb
  maxPhotoAttempts: 3 as ConfigSlice["maxPhotoAttempts"],
};

export const createConfigSlice: SliceCreator<CommonState, ConfigSlice> = (
  set,
) => ({
  ...defaultState,

  updateMaxPhotoAttempts: () => {
    set(
      ({ passportPhoto: { config } }) => {
        config.maxPhotoAttempts = 6;
      },
      false,
      "[config.updateMaxPhotoAttempts]: Set max photo attempts to 6",
    );
  },
});
