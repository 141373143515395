"use client";
import type { ReactNode } from "react";
import { cn } from "@/lib/utils";
import { HtmlTagDescriptor } from "vite";

type GridProps = {
  children?: ReactNode;
  className?: string;
  as?: "div" | "ul";
};

const Grid = ({ children, className, as: T = "div", ...props }: GridProps) => {
  return (
    <T className={cn("fr-grid fr-grid-cols-12 fr-gap-4", className)} {...props}>
      {children}
    </T>
  );
};

export { Grid };
