import { headers, endpoints } from "@/constants.ts";
import type { RegulaResponse } from "@/types/regula.ts";
import { useBoundStore } from "@/zustand-store/zustand-store.ts";

type ValidatePassportPhotoViaRegulaProps = {
  base64Picture: string;
  fileType: File["type"];
};

export async function validatePassportPhotoViaRegula({
  base64Picture,
  fileType,
}: ValidatePassportPhotoViaRegulaProps) {
  const { getState } = useBoundStore;
  if (!getState().passportPhoto.validation.isValidating) {
    getState().passportPhoto.validation.setIsValidating(true);
  }

  if (getState().passportPhoto.webcam.isActive) {
    getState().passportPhoto.webcam.setIsActive(false);
  }

  try {
    const response = await fetch(endpoints.validatePassportPhotoViaRegula, {
      method: "POST",
      headers,
      body: JSON.stringify({ base64Picture }),
    });
    const photoValidationData: RegulaResponse = await response.json();
    const src = `data:${fileType};base64,${photoValidationData.results.detections[0].crop}`;
    const quality = photoValidationData.results.detections[0].quality;
    const validatedPhoto = {
      quality,
      src,
    };

    getState().passportPhoto.photos.attachPhoto(validatedPhoto);
    getState().passportPhoto.validation.setValidationResults(quality);
    getState().passportPhoto.validation.setIsValidating(false);
    getState().passportPhoto.validation.startStaggeringValidationList(
      quality.details.length,
    );

    return photoValidationData;
  } catch (e) {
    // We don't reset validating state in finally block as we want to stagger the validation list on success
    getState().passportPhoto.validation.setIsValidating(false);
    console.log(e);
    return null;
  }
}
