import { Button } from "@/components/Button/Button.tsx";
import { useBoundStore } from "@/zustand-store/zustand-store.ts";
import { usePassportPhotoMutations } from "@/hooks/usePassportPhotoMutationts/usePassportPhotoMutations.ts";
import { useApplicantContext, useFormMetadataContext } from "@/providers";

const StepNavigation = () => {
  const { product } = useFormMetadataContext();
  const { applicantToken } = useApplicantContext();
  const { getPortraitUploadUrlMutation } = usePassportPhotoMutations();
  const {
    passportPhoto: {
      validation: { isValidating, isStaggeringValidationList },
      photos: { attachedPhotos },
      upload: { isPreparingPhotoForUpload, isUploading },
    },
  } = useBoundStore();

  const handleContinueClick = () =>
    getPortraitUploadUrlMutation.mutate({
      applicationToken: applicantToken!,
      applicationType: product!,
    });

  const isDisabled =
    isValidating ||
    isStaggeringValidationList ||
    isPreparingPhotoForUpload ||
    isUploading;

  return (
    <div className="fr-flex fr-w-full fr-justify-between sm:fr-flex-row fr-flex-col sm:fr-space-y-0">
      <Button
        variant="outline"
        className="sm:fr-order-1 fr-order-2 fr-my-3 sm:fr-my-0"
        disabled={isDisabled}
      >
        Previous Step
      </Button>
      <Button
        variant="link"
        className="fr-underline sm:fr-order-2 fr-order-1"
        disabled={isDisabled}
      >
        Skip
      </Button>
      <Button
        className="fr-text-white fr-order-3"
        onClick={handleContinueClick}
        disabled={isDisabled || attachedPhotos.length === 0}
      >
        Continue
      </Button>
    </div>
  );
};

export default StepNavigation;
