import { useBoundStore } from "@/zustand-store/zustand-store.ts";
import convertBase64ToBlob from "@/utils/convertBase64ToArrayBuffer/convertBase64ToArrayBuffer.ts";
import type { Photo } from "@/types/regula.ts";

export async function uploadPassportPhoto(uploadUrl: string) {
  try {
    const { src, mimeType } = useBoundStore.getState().passportPhoto.photos
      .photoWithTheLeastValidationErrors as Photo;

    const blob = convertBase64ToBlob({ base64: src, type: mimeType });

    const response = await fetch(uploadUrl, {
      method: "PUT",
      headers: {
        "Content-Type": mimeType,
        "x-form-builder-version": "1.0",
      },
      body: JSON.stringify({ blob }),
    });
  } catch (e) {
    console.log(e);
  }
}
