import PassportPhotoInstructionsIcon from "@/icons/PassportPhotoInstructionsIcon.tsx";

const PhotoInstructions = () => {
  return (
    <div className="fr-flex fr-flex-col fr-items-center fr-space-y-3">
      <div className="lg:fr-scale-100 fr-scale-75 lg:fr-mb-0 lg:fr-mt-0 -fr-mb-4 -fr-mt-10">
        <PassportPhotoInstructionsIcon />
      </div>
      <div className="fr-text-center">
        <p>
          For your photo, please remove any hats and glasses. Maintain a neutral
          expression, avoid any smiles, and look straight into the camera.
        </p>
        <p>
          Ensure that you are positioned in front of a white wall or background.
        </p>
      </div>
    </div>
  );
};

export default PhotoInstructions;
