import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/Form/Form.tsx";

import type { FormSelectNode } from "@/types/node.ts";
import { GridCol } from "@/components/Grid/Col.tsx";
import { useApplicantMutations } from "@/hooks";
import { useAnalytics, useApplicantContext } from "@/providers";
import { useFormContext } from "react-hook-form";
import { cn } from "@/lib/utils";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/Popover/Popover";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/Command/Command";
import { ChevronsUpDown } from "lucide-react";
import { Button } from "@/components/Button/Button";
import { useState, useCallback } from "react";
import * as React from "react";
import { useFieldRules } from "@/hooks/useFieldRules/useFieldRules.ts";
import { useFieldOptions } from "@/hooks/useFieldOptions/useFieldOptions.ts";
import Flag from "@/components/Flag/Flag.tsx";
import ButtonLabel from "@/components/Form/Fields/SelectField/ButtonLabel.tsx";

type HandleChangeProps = {
  name: string;
  value: string;
  onChange: (value: string) => void;
};

const SelectField = ({
  name,
  label,
  description,
  placeholder,
  visibleIf,
  visibleIfOp,
  options,
  gridCol,
  rules,
  tooltip,
  emptyMessage,
}: FormSelectNode & { gridCol?: number }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { applicantToken } = useApplicantContext();
  const { control, clearErrors, setValue } = useFormContext();
  const { validationRules, isVisible, defaultValue } = useFieldRules({
    fieldName: name,
    rules,
    visibleIf,
    visibleIfOp,
    emptyMessage,
  });

  const { fieldOptions } = useFieldOptions({ name, options });
  const { updateTemporaryApplicantMutation } = useApplicantMutations();

  const { trackFormField } = useAnalytics();

  const handleChange = useCallback(
    ({ name, value, onChange }: HandleChangeProps) => {
      if (name === "jobOccupation") {
        setValue("jobTitle", "");
      }

      if (applicantToken) {
        updateTemporaryApplicantMutation.mutate({
          field: name,
          value,
        });
      }
      onChange(value);
      clearErrors(name);
      setIsDropdownOpen(false);

      trackFormField({
        fieldType: "select",
        fieldName: name,
        fieldInteraction: "change",
      });
    },
    [
      applicantToken,
      updateTemporaryApplicantMutation,
      setValue,
      clearErrors,
      setIsDropdownOpen,
      trackFormField,
    ],
  );

  const handleButtonClick = () => setIsDropdownOpen((prevState) => !prevState);
  const hasTemporarilyShownIndexValues = [
    "birthCountry",
    "passportNationalityCode",
    "passportNationality",
    "addressCountry",
    "nationalities",
    "jobCountry",
    "jobOccupation",
    "jobTitle",
  ].includes(name);

  if (!isVisible) return null;

  return (
    <GridCol span={gridCol} sm={12}>
      <FormField
        control={control}
        rules={validationRules}
        name={name}
        defaultValue={defaultValue}
        render={({ field }) => (
          <FormItem>
            <FormLabel tooltip={tooltip} description={description}>
              {label}
            </FormLabel>
            <Popover open={isDropdownOpen}>
              <PopoverTrigger asChild>
                <FormControl>
                  <Button
                    variant="outline"
                    role="combobox"
                    className={cn(
                      "fr-w-full fr-justify-between fr-font-normal hover:fr-bg-white",
                    )}
                    onClick={handleButtonClick}
                  >
                    <ButtonLabel
                      field={field}
                      fieldOptions={fieldOptions}
                      defaultValue={defaultValue}
                      placeholder={placeholder}
                    />
                    <ChevronsUpDown className="fr-ml-2 fr-h-4 fr-w-4 fr-shrink-0 fr-opacity-50" />
                  </Button>
                </FormControl>
              </PopoverTrigger>
              <PopoverContent className="fr-p-0" align="start">
                <Command>
                  <CommandInput placeholder="Search..." />
                  <CommandList>
                    <CommandEmpty>No results found.</CommandEmpty>
                    <CommandGroup>
                      {fieldOptions?.map((option: any) => (
                        <CommandItem
                          value={option.label}
                          key={option.value}
                          onSelect={() => {
                            handleChange({
                              name,
                              value: option.value,
                              onChange: field.onChange,
                            });
                          }}
                        >
                          {/*<Check*/}
                          {/*  className={cn(*/}
                          {/*    "fr-mr-2 fr-h-4 fr-w-4",*/}
                          {/*    option.value === field.value*/}
                          {/*      ? "fr-opacity-100"*/}
                          {/*      : "fr-opacity-0",*/}
                          {/*  )}*/}
                          {/*/>*/}
                          {option.code ? (
                            <div className="fr-flex fr-space-x-2">
                              <Flag
                                alt={option.label}
                                countryCode={option.code}
                              />
                              <p>
                                {hasTemporarilyShownIndexValues
                                  ? `${option.value}. ${option.label}`
                                  : option.label}
                              </p>
                            </div>
                          ) : hasTemporarilyShownIndexValues ? (
                            `${option.value}. ${option.label}`
                          ) : (
                            option.label
                          )}
                        </CommandItem>
                      ))}
                    </CommandGroup>
                  </CommandList>
                </Command>
              </PopoverContent>
            </Popover>
            <FormMessage />
          </FormItem>
        )}
      />
    </GridCol>
  );
};

export default SelectField;
