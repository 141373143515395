import "./index.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { TooltipProvider } from "@/components/Tooltip/Tooltip";
import FormShell from "@/FormShell";
import {
  ApplicantTokenProvider,
  FormMetadataProvider,
  SearchParamsProvider,
  AnalyticsProvider,
} from "@/providers";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

type FormRendererProps = {
  form?: any;
};

const hourStaleTime = 1000 * 60 * 60;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
      staleTime: hourStaleTime,
    },
  },
});

const FormRenderer = ({ form }: FormRendererProps) => {
  return (
    <SearchParamsProvider>
      <QueryClientProvider client={queryClient}>
        <FormMetadataProvider form={form}>
          <ApplicantTokenProvider>
            <AnalyticsProvider token={import.meta.env.VITE_MIXPANEL_TOKEN}>
              <TooltipProvider>
                <FormShell />
                <ToastContainer />
              </TooltipProvider>
            </AnalyticsProvider>
          </ApplicantTokenProvider>
        </FormMetadataProvider>
      </QueryClientProvider>
    </SearchParamsProvider>
  );
};

export default FormRenderer;
export { FormRenderer };
