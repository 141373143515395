import ReactWebcam from "react-webcam";
import { useBoundStore } from "@/zustand-store/zustand-store.ts";
import { useRef } from "react";
import { Button } from "@/components/Button/Button.tsx";
import Spinner from "@/components/Spinner/Spinner.tsx";
import CameraSwitch from "@/icons/CameraSwitch.tsx";
import { usePassportPhotoMutations } from "@/hooks/usePassportPhotoMutationts/usePassportPhotoMutations.ts";
import { cn } from "@/lib/utils";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/Select/Select.tsx";
import { Video } from "lucide-react";
import SelectDevice from "@/components/PassportPhoto/ActiveComponent/Webcam/SelectDevice.tsx";
import CameraLoading from "@/components/PassportPhoto/ActiveComponent/Webcam/CameraLoading.tsx";
import CameraActions from "@/components/PassportPhoto/ActiveComponent/Webcam/CameraActions.tsx";
import NoCameraFound from "@/components/PassportPhoto/ActiveComponent/Webcam/NoCameraFound.tsx";

const Webcam = () => {
  const {
    passportPhoto: {
      config: { containerHeight },
      webcam: {
        isActive,
        facingMode,
        isActivating,
        activeDeviceId,
        devices,
        setIsActive,
        setIsActivating,
      },
    },
  } = useBoundStore();
  const webcamRef = useRef<ReactWebcam>(null);
  const screenshotFormat = "image/png";

  if (!activeDeviceId) {
    return <NoCameraFound />;
  }

  return (
    <div
      className={cn(
        containerHeight,
        "fr-flex fr-flex-col fr-space-y-4 fr-justify-center",
      )}
    >
      {isActive && devices.length > 0 && <SelectDevice />}
      <div className="fr-relative fr-w-[300px] fr-h-[300px] fr-flex fr-items-center fr-justify-center fr-overflow-hidden fr-rounded-md">
        {isActivating && !isActive && <CameraLoading />}
        <ReactWebcam
          audio={false}
          ref={webcamRef}
          screenshotFormat={screenshotFormat}
          className="fr-max-w-none -fr-scale-x-100"
          videoConstraints={{
            height: 350,
            width: 350,
            facingMode: { ideal: facingMode },
            deviceId: activeDeviceId,
          }}
          onUserMedia={() => {
            setIsActivating(false);
            setIsActive(true);
          }}
          onUserMediaError={() => {
            setIsActive(false);
            setIsActivating(false);
          }}
        />
        {isActive && (
          <div
            className={`fr-absolute fr-top-0 fr-left-0 fr-w-full fr-h-full fr-pointer-events-none fr-bg-[url('${import.meta.env.VITE_APP_HOST_URL}/assets/selfie_frame.png')] fr-bg-contain fr-bg-no-repeat`}
          />
        )}
      </div>

      {isActive && (
        <CameraActions
          webcamRef={webcamRef}
          screenshotFormat={screenshotFormat}
        />
      )}
    </div>
  );
};

export default Webcam;
