import ActiveComponent from "@/components/PassportPhoto/ActiveComponent/ActiveComponent.tsx";
import ReminderMessage from "@/components/PassportPhoto/ReminderMessage/ReminderMessage.tsx";
import PhotoNavigation from "@/components/PassportPhoto/ActiveComponent/Photo/PhotoNavigation/PhotoNavigation.tsx";
import { useBoundStore } from "@/zustand-store/zustand-store.ts";
import StepNavigation from "@/components/PassportPhoto/StepNavigation/StepNavigation.tsx";
import { Grid } from "@/components/Grid/Grid.tsx";
import { GridCol } from "@/components/Grid/Col.tsx";
import PhotoInstructions from "@/components/PassportPhoto/ActiveComponent/PhotoInstructions/PhotoInstructions.tsx";

const PassportPhoto = () => {
  const {
    passportPhoto: {
      photos: { attachedPhotos },
    },
  } = useBoundStore();

  return (
    <Grid className="lg:fr-space-y-0 fr-space-y-3">
      <GridCol
        span={6}
        lg={12}
        className="fr-border fr-px-[20px] fr-py-[60px] fr-rounded-md fr-space-y-6"
      >
        <div className="fr-border fr-border-blue-300 fr-bg-blue-50 fr-rounded-md fr-py-6 fr-flex fr-flex-col fr-items-center">
          <ActiveComponent />
        </div>
        {attachedPhotos.length > 0 && <PhotoNavigation />}
        <ReminderMessage />
        <StepNavigation />
      </GridCol>
      <GridCol
        span={6}
        lg={12}
        className="lg:fr-flex fr-items-center fr-hidden"
      >
        <PhotoInstructions />
      </GridCol>
    </Grid>
  );
};

export default PassportPhoto;
