import { useMutation } from "@tanstack/react-query";
import { validatePassportPhotoViaRegula } from "@/api/validatePassportPhotoViaRegula/validatePassportPhotoViaRegula";
import { getPortraitUploadUrl } from "@/api/getPortraitUploadUrl/getPortraitUploadUrl";
import { uploadPassportPhoto } from "@/api/uploadPassportPhoto/uploadPassportPhoto";
import { sendEmailToTakePhotoOnMobile } from "@/api/sendEmailToTakePhotoOnMobile/sendEmailToTakePhotoOnMobile";
import { useBoundStore } from "@/zustand-store/zustand-store.ts";
import { toast } from "react-toastify";

export function usePassportPhotoMutations() {
  const {
    passportPhoto: {
      email: {
        setIsSendConfirmationModalOpen,
        setIsWaitingForPhotoUploadOnMobile,
      },
    },
  } = useBoundStore();
  const validatePassportPhotoViaRegulaMutation = useMutation({
    mutationKey: ["validatePassportPhotoViaRegula"],
    mutationFn: validatePassportPhotoViaRegula,
    onError: () => {
      toast.error("Uh oh! Something went wrong.");
    },
  });

  const getPortraitUploadUrlMutation = useMutation({
    mutationKey: ["getPortraitUploadUrl"],
    mutationFn: getPortraitUploadUrl,
    onSuccess: (uploadUrl) => {
      uploadPassportPhotoMutation.mutate(uploadUrl);
    },
    onError: () => {
      toast.error("Uh oh! Something went wrong.");
    },
  });

  const uploadPassportPhotoMutation = useMutation({
    mutationKey: ["uploadPassportPhoto"],
    mutationFn: uploadPassportPhoto,
    onError: () => {
      toast.error("Uh oh! Something went wrong.");
    },
  });

  const sendEmailToTakePhotoOnMobileMutation = useMutation({
    mutationKey: ["uploadPassportPhoto"],
    mutationFn: sendEmailToTakePhotoOnMobile,
    onSuccess: () => {
      setIsWaitingForPhotoUploadOnMobile(true);
    },
    onError: (e) => {
      toast.error("Uh oh! Something went wrong.");
    },
    onSettled: () => {
      setIsSendConfirmationModalOpen(false);
    },
  });

  return {
    validatePassportPhotoViaRegulaMutation,
    getPortraitUploadUrlMutation,
    sendEmailToTakePhotoOnMobileMutation,
  };
}
