"use client";
import { cn } from "@/lib/utils";
import type { ReactNode } from "react";
import { generateSpan } from "./utils";

type ColProps = {
  span?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  children?: ReactNode;
  className?: string;
  as?: "div" | "li";
};

const GridCol = ({
  span,
  lg,
  md,
  sm,
  xl,
  children,
  className,
  as: T = "div",
  ...props
}: ColProps) => {
  const colClasses = cn(
    // By default, Row takes 12 columns
    !span && "fr-col-span-12",
    // `span` prop applies to all breakpoints
    span && generateSpan({ breakpoint: "span", cols: span }),
    sm && generateSpan({ breakpoint: "sm", cols: sm }),
    md && generateSpan({ breakpoint: "md", cols: md }),
    lg && generateSpan({ breakpoint: "lg", cols: lg }),
    xl && generateSpan({ breakpoint: "xl", cols: xl }),
  );

  return (
    <T className={cn(colClasses, className)} {...props}>
      {children}
    </T>
  );
};

export { GridCol };
