import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import {
  createValidationSlice,
  type ValidationSlice,
} from "@/zustand-store/passportPhoto/validationSlice/validationSlice";
import {
  createWebcamSlice,
  type WebcamSlice,
} from "@/zustand-store/passportPhoto/webcamSlice/webcamSlice";
import {
  createUploadSlice,
  type UploadSlice,
} from "@/zustand-store/passportPhoto/uploadSlice/uploadSlice";
import {
  createEmailSlice,
  type EmailSlice,
} from "@/zustand-store/passportPhoto/emailSlice/emailSlice";
import {
  createConfigSlice,
  type ConfigSlice,
} from "@/zustand-store/passportPhoto/configSlice/configSlice.ts";
import {
  createPhotosSlice,
  type PhotosSlice,
} from "@/zustand-store/passportPhoto/photoSlice/photosSlice.ts";

export type CommonState = {
  passportPhoto: {
    config: ConfigSlice;
    email: EmailSlice;
    webcam: WebcamSlice;
    upload: UploadSlice;
    validation: ValidationSlice;
    photos: PhotosSlice;
  };
};

export const useBoundStore = create<CommonState>()(
  devtools(
    immer((...a) => ({
      passportPhoto: {
        config: createConfigSlice(...a),
        email: createEmailSlice(...a),
        webcam: createWebcamSlice(...a),
        upload: createUploadSlice(...a),
        validation: createValidationSlice(...a),
        photos: createPhotosSlice(...a),
      },
    })),
  ),
);
