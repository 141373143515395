import { motion } from "framer-motion";
import { useBoundStore } from "@/zustand-store/zustand-store.ts";
import Spinner from "@/components/Spinner/Spinner.tsx";
import { CircleCheckBig, CircleX } from "lucide-react";
import { cn } from "@/lib/utils";

const container = {
  hidden: { opacity: 0 },
  show: { opacity: 1, transition: { staggerChildren: 0.1 } },
};

const item = { hidden: { opacity: 0 }, show: { opacity: 1 } };

const StaggeringValidationList = () => {
  const {
    passportPhoto: {
      config: { animationDuration, staggerDelayS, containerHeight },
      validation: { results },
    },
  } = useBoundStore();

  return (
    <motion.ul
      className={cn(containerHeight, "fr-overflow-auto fr-px-3")}
      variants={container}
      initial="hidden"
      animate="show"
    >
      {results.map((validation, index) => {
        return (
          <motion.li
            variants={item}
            key={validation.validationName}
            className="fr-flex fr-items-center fr-space-x-2 fr-relative"
          >
            <div>
              <motion.div
                initial={{ y: 0, opacity: 1 }}
                animate={{ y: 30, opacity: 0 }}
                transition={{
                  duration: animationDuration,
                  delay: index * staggerDelayS,
                }}
              >
                <Spinner size="sm" />
              </motion.div>
              <motion.div
                // className={`${validation.isValid ? "fr-text-green-600" : "fr-text-red-600"}`}
                initial={{ y: -30, color: "unset", opacity: 0 }}
                animate={{
                  y: -6.5,
                  color: validation.isValid ? "#10A55FFF" : "red",
                  opacity: 1,
                }}
                transition={{
                  duration: animationDuration,
                  delay: index * staggerDelayS,
                }}
              >
                {validation.isValid ? (
                  <CircleCheckBig size={16} />
                ) : (
                  <CircleX size={16} />
                )}
              </motion.div>
            </div>

            <motion.p
              initial={{ color: "unset" }}
              animate={{ color: validation.isValid ? "#10A55FFF" : "red" }}
              transition={{
                duration: animationDuration,
                delay: index * staggerDelayS,
              }}
            >
              {validation.validationName}
            </motion.p>
          </motion.li>
        );
      })}
    </motion.ul>
  );
};

export default StaggeringValidationList;
