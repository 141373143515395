import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/Form/Form.tsx";
import { Input } from "@/components/Input/Input.tsx";
import type { FormInputNode } from "@/types/node.ts";
import { GridCol } from "@/components/Grid/Col.tsx";
import { useFormContext } from "react-hook-form";
import { useCallback, useRef } from "react";
import { useFieldRules } from "@/hooks/useFieldRules/useFieldRules.ts";
import { useAnalytics } from "@/providers";

type HandleChangeProps = {
  onChange: (value: string) => void;
};

type HandleBlurProps = {
  onBlur: () => void;
};

const InputField = ({
  name,
  label,
  description,
  placeholder,
  visibleIf,
  visibleIfOp,
  gridCol,
  rules,
  emptyMessage,
  tooltip,
}: FormInputNode & { gridCol?: number }) => {
  const ref = useRef<HTMLInputElement>(null);
  const { control } = useFormContext();
  const { validationRules, isVisible, transform } = useFieldRules({
    fieldName: name,
    rules,
    visibleIf,
    visibleIfOp,
    emptyMessage,
    ref,
  });
  const { trackFormField } = useAnalytics();

  const getTransformedValue = () => {
    const value = ref?.current?.value || "";
    let transformedValue = value;

    if (value && transform) {
      switch (transform) {
        case "capitalize": {
          transformedValue = value.replace(
            value[0],
            value[0]?.toUpperCase() || "",
          );
          break;
        }
        case "uppercase": {
          transformedValue = value.replace(value, value?.toUpperCase() || "");
          break;
        }
      }
    }

    return transformedValue || "";
  };

  const handleFocus = useCallback(() => {
    trackFormField({
      fieldType: "input",
      fieldName: name,
      fieldInteraction: "focus",
    });
  }, [trackFormField]);

  const handleChange = useCallback(
    ({ onChange }: HandleChangeProps) => {
      const value = getTransformedValue();

      onChange(value);
      trackFormField({
        fieldType: "input",
        fieldName: name,
        fieldInteraction: "change",
      });
    },
    [trackFormField],
  );

  const handleBlur = useCallback(
    ({ onBlur }: HandleBlurProps) => {
      onBlur();
      trackFormField({
        fieldType: "input",
        fieldName: name,
        fieldInteraction: "focus-out",
      });
    },
    [trackFormField],
  );

  if (!isVisible) return null;

  return (
    <GridCol span={gridCol} sm={12}>
      <FormField
        name={name}
        control={control}
        rules={validationRules}
        render={({ field }) => (
          <FormItem>
            <FormLabel description={description} tooltip={tooltip}>
              {label}
            </FormLabel>
            <FormControl>
              <Input
                placeholder={placeholder}
                {...field}
                ref={ref}
                onFocus={handleFocus}
                onChange={() => handleChange({ onChange: field.onChange })}
                onBlur={() => handleBlur({ onBlur: field.onBlur })}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </GridCol>
  );
};

export default InputField;
