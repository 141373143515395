import { useBoundStore } from "@/zustand-store/zustand-store.ts";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalDescription,
  ModalHeader,
  ModalTitle,
} from "@/components/Modal/Modal.tsx";
import { Button } from "@/components/Button/Button.tsx";

const PhotoTipsModal = () => {
  const {
    passportPhoto: {
      photos: { isPhotoTipsModalOpen, togglePhotoTipsModal },
    },
  } = useBoundStore();

  const handleCloseModal = () => togglePhotoTipsModal(false);

  return (
    <Modal open={isPhotoTipsModalOpen} onOpenChange={handleCloseModal}>
      <ModalContent>
        <ModalHeader>
          <ModalTitle>
            Trouble with Passport Photo? Here are some tips:
          </ModalTitle>
          <ModalDescription> </ModalDescription>
        </ModalHeader>
        <ModalBody className="md:fr-p-0 fr-px-4 fr-pb-4">
          <p>
            <b>Neutral Face & Eye-Level Camera</b>
          </p>
          <p>
            Keep a relaxed expression (no smile/frown), look directly at the
            camera, and keep it at eye level.
          </p>
          <br />

          <p>
            <b>Good Lighting & Plain Background</b>
          </p>
          <p>
            Use even lighting (no shadows) with a plain white/off-white
            background.
          </p>
          <br />

          <p>
            <b>No Glasses & Clear Face</b>
          </p>
          <p>
            Remove glasses (unless necessary), and keep hair away from your
            face, forehead, and ears
          </p>
        </ModalBody>
        <Button className="fr-text-white" onClick={handleCloseModal}>
          Acknowledge
        </Button>
      </ModalContent>
    </Modal>
  );
};

export default PhotoTipsModal;
