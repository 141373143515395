import Spinner from "@/components/Spinner/Spinner.tsx";

const CameraLoading = () => {
  return (
    <div className="fr-absolute fr-flex fr-space-x-3 fr-items-center">
      <Spinner size="sm" /> <p>Turning Camera On...</p>
    </div>
  );
};

export default CameraLoading;
