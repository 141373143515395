import styles from "./Spinner.module.css";
import { cn } from "@/lib/utils";
import type { DetailedHTMLProps, HTMLAttributes } from "react";

type SizeProps = {
  size?: "sm" | "md" | "lg";
  className?: string;
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const Spinner = ({ size = "md", className, ...props }: SizeProps) => {
  const sizeObj = {
    sm: "fr-size-4 fr-p-[2px]",
    md: "fr-size-6",
    lg: "fr-size-10",
  };

  return (
    <div
      className={cn(
        "spinner",
        styles.loader,
        sizeObj[size],
        "fr-bg-blue-400",
        className,
      )}
      {...props}
    />
  );
};

export default Spinner;
