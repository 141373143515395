import { useBoundStore } from "@/zustand-store/zustand-store.ts";
import { CircleX } from "lucide-react";

const ValidationErrorsList = () => {
  const {
    passportPhoto: {
      photos: { selectedPhoto },
    },
  } = useBoundStore();

  if (!selectedPhoto) return null;

  return (
    <div className="fr-space-y-3 fr-flex fr-flex-col fr-items-center fr-px-3">
      <p className="fr-text-center">
        It looks like your photo doesn't meet the following requirements:
      </p>
      <ul className="fr-bg-white fr-shadow fr-p-3 fr-space-y-2 fr-max-w-[375px] sm:fr-max-h-[170px] fr-max-h-[200px] fr-overflow-auto">
        {selectedPhoto.validationErrors.map((err) => (
          <li
            key={err.name}
            className="fr-grid fr-grid-cols-[1fr_20px] fr-gap-3 fr-items-top [&:not(:last-child)]:fr-pb-2 [&:not(:last-child)]:fr-border-b fr-border-black-300"
          >
            <p>{err.description}</p>
            <CircleX
              color="red"
              size={22}
              className="fr-inline-block fr-pt-[4px]"
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ValidationErrorsList;
