import Spinner from "@/components/Spinner/Spinner.tsx";
import { cn } from "@/lib/utils";
import { useBoundStore } from "@/zustand-store/zustand-store.ts";

const WaitingForPhotoFromMobile = () => {
  const {
    passportPhoto: {
      config: { containerHeight },
    },
  } = useBoundStore();

  return (
    <div
      className={cn(
        containerHeight,
        "passport-photo-wrapper fr-text-center fr-space-y-3 fr-flex fr-flex-col fr-justify-between",
      )}
    >
      <h2 className="fr-text-2xl">
        Please Capture Your Photo On Your Mobile Phone
      </h2>
      <div className="fr-flex fr-items-center fr-space-x-3 fr-justify-center">
        <Spinner size="sm" />{" "}
        <p>Waiting for photo upload through your mobile phone...</p>
      </div>
      <div>
        <p>Don't want to take your photo now?</p>
        <p>You can skip this step, and we'll remind you later.</p>
      </div>
    </div>
  );
};

export default WaitingForPhotoFromMobile;
