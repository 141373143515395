import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/lib/utils";

const buttonVariants = cva(
  "fr-inline-flex fr-items-center fr-justify-center fr-whitespace-nowrap fr-rounded-md fr-text-sm fr-font-medium fr-ring-offset-background fr-transition-colors focus-visible:fr-outline-none focus-visible:fr-ring-2 focus-visible:fr-ring-ring focus-visible:fr-ring-offset-2 disabled:fr-pointer-events-none disabled:fr-opacity-50",
  {
    variants: {
      variant: {
        default:
          "fr-bg-light-blue-300 fr-text-primary-foreground hover:fr-bg-light-blue-400",
        destructive:
          "fr-bg-destructive fr-text-destructive-foreground hover:fr-bg-destructive/90",
        outline:
          "fr-border fr-border-input fr-bg-background hover:fr-bg-accent hover:fr-text-accent-foreground",
        secondary:
          "fr-bg-secondary fr-text-secondary-foreground hover:fr-bg-secondary/80",
        ghost: "hover:fr-bg-accent hover:fr-text-accent-foreground",
        link: "fr-text-primary fr-underline-offset-4 hover:fr-underline",
      },
      size: {
        default: "fr-h-10 fr-px-4 fr-py-2",
        sm: "fr-h-9 fr-rounded-md fr-px-3",
        lg: "fr-h-11 fr-rounded-md fr-px-8",
        icon: "fr-h-10 fr-w-10",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size }), className)}
        ref={ref}
        {...props}
      />
    );
  },
);
Button.displayName = "Button";

export { Button, buttonVariants };
