import { Button } from "@/components/Button/Button.tsx";
import CameraSwitch from "@/icons/CameraSwitch.tsx";
import { usePassportPhotoMutations } from "@/hooks/usePassportPhotoMutationts/usePassportPhotoMutations.ts";
import { useBoundStore } from "@/zustand-store/zustand-store.ts";

interface CameraActionsProps {
  webcamRef: any;
  screenshotFormat: string;
}

const CameraActions = ({ webcamRef, screenshotFormat }: CameraActionsProps) => {
  const {
    passportPhoto: {
      webcam: { setIsActive, toggleFacingMode },
    },
  } = useBoundStore();

  const { validatePassportPhotoViaRegulaMutation } =
    usePassportPhotoMutations();

  const handleCancelClick = () => setIsActive(false);

  const handleTakePhotoClick = () => {
    const imageSrc = webcamRef.current?.getScreenshot();
    if (!imageSrc) return;
    validatePassportPhotoViaRegulaMutation.mutate({
      base64Picture: imageSrc,
      fileType: screenshotFormat,
    });
  };

  const handleCameraSwitchClick = () => toggleFacingMode();

  return (
    <div className="fr-flex fr-justify-between">
      <Button variant="link" onClick={handleCancelClick}>
        Cancel
      </Button>
      <Button
        variant="default"
        className="fr-text-white"
        onClick={handleTakePhotoClick}
      >
        Take Photo
      </Button>
      <Button
        variant="outline"
        className="fr-text-white"
        onClick={handleCameraSwitchClick}
      >
        <CameraSwitch />
      </Button>
    </div>
  );
};

export default CameraActions;
