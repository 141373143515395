type UniqueArrayByKeyProps<T> = {
  data: T[];
  key: keyof T;
};

const uniqueArrayByKey = <T>({ data, key }: UniqueArrayByKeyProps<T>): T[] => {
  const uniqueValues = new Set();
  const uniqueArray = data.filter((item) => {
    const value = item[key];
    if (!uniqueValues.has(value)) {
      uniqueValues.add(value);
      return true;
    }
    return false;
  });

  return uniqueArray;
};

export { uniqueArrayByKey };
