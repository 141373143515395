import Spinner from "@/components/Spinner/Spinner.tsx";
import { cn } from "@/lib/utils";
import { useBoundStore } from "@/zustand-store/zustand-store.ts";

const PreparingForUpload = () => {
  const {
    passportPhoto: {
      config: { containerHeight },
    },
  } = useBoundStore();
  return (
    <div
      className={cn(containerHeight, "fr-flex fr-space-x-3 fr-items-center")}
    >
      <Spinner />
      <p>Preparing photo for upload...</p>
    </div>
  );
};

export default PreparingForUpload;
