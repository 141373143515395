import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export { extractAllRulesFromSchema } from "./extractAllRulesFromSchema";
export { getConditionValue } from "./getConditionValue";
export { getDefaultValuesFromUISchema } from "./getDefaultValuesFromUISchema";
export { getFieldNames } from "./getFieldNames";
export { getDataKeyByFieldName } from "./queryToFieldMapping";
export { shapeRulesForReactHookForm } from "./shapeRulesForReactHookForm";
export { findFormRendererScript } from "./findFormRendererScript/findFormRendererScript";
export { getNonEmptyProperties } from "./getNonEmptyProperties";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
