import type { CommonState } from "@/zustand-store/zustand-store.ts";
import type { SliceCreator } from "@/types";

export type EmailSlice = {
  isSendConfirmationModalOpen: boolean;
  isWaitingForPhotoUploadOnMobile: boolean;
  setIsSendConfirmationModalOpen: (isOpen: boolean) => void;
  setIsWaitingForPhotoUploadOnMobile: (
    isWaitingForPhotoUploadOnMobile: boolean,
  ) => void;
};

const defaultState = {
  isSendConfirmationModalOpen: false,
  isWaitingForPhotoUploadOnMobile: false,
};

export const createEmailSlice: SliceCreator<CommonState, EmailSlice> = (
  set,
  get,
) => ({
  ...defaultState,

  setIsSendConfirmationModalOpen: (isSendConfirmationModalOpen) => {
    set(
      ({ passportPhoto: { email } }) => {
        email.isSendConfirmationModalOpen = isSendConfirmationModalOpen;
      },
      false,
      `[email.setIsSendConfirmationModalOpen]: ${isSendConfirmationModalOpen ? "Opened" : "closed"} send email confirmation modal`,
    );
  },

  setIsWaitingForPhotoUploadOnMobile: (isWaitingForPhotoUploadOnMobile) => {
    set(
      ({ passportPhoto: { email } }) => {
        email.isSendConfirmationModalOpen = isWaitingForPhotoUploadOnMobile;
      },
      false,
      `[email.setIsWaitingForPhotoUploadOnMobile]: ${isWaitingForPhotoUploadOnMobile ? "Started waiting for" : "Received"} photo upload from mobile`,
    );
  },
});
