import { GridCol } from "@/components/Grid/Col.tsx";
import { FormField, FormItem } from "@/components/Form/Form.tsx";
import { useFormContext } from "react-hook-form";
import { useFieldRules } from "@/hooks/useFieldRules/useFieldRules.ts";
import PassportPhoto from "@/components/PassportPhoto/PassportPhoto.tsx";
import type { FormInputNode } from "@/types/node.ts";

const PassportPhotoField = ({
  name,
  gridCol,
  rules,
  visibleIf,
  visibleIfOp,
  emptyMessage,
}: FormInputNode & { gridCol?: number }) => {
  const { control, getValues, setError, clearErrors } = useFormContext();
  const { validationRules, isVisible } = useFieldRules({
    fieldName: name,
    rules,
    visibleIf,
    visibleIfOp,
    emptyMessage,
  });

  if (!isVisible) return null;

  return (
    <GridCol span={gridCol}>
      <FormField
        control={control}
        name={name}
        rules={validationRules}
        render={({ field }) => (
          <FormItem>
            <PassportPhoto />
          </FormItem>
        )}
      />
    </GridCol>
  );
};

export default PassportPhotoField;
